import Debug from "debug";
import ExperimentEngine from "@bookingcom/experiment-tracking-clientside";
import { ExperimentEngineInterface } from "@bookingcom/experiment-tracking-clientside/dist/src/ExperimentEngine";
import { RequestContext } from "@flights/turbine";

import * as watchEt from "./watch-et";

const debug = Debug("utils:etV2");

let getAsyncRequestContext: () => RequestContext;
if (process.env.BUILD_TARGET === "server") {
  getAsyncRequestContext = require("@flights/turbine").getAsyncRequestContext;
}

type EtV2 = ExperimentEngineInterface & {
  sinkData: () => void;
  trackWithDefaultStage: (hashedTagname: string, defaultId?: number) => number;
};

const etV2Fallback: EtV2 = {
  track: () => 0,
  stage: () => false,
  customGoal: () => false,
  goal: () => false,
  goalWithValue: () => false,
  updateStateBlobAndVariants: () => {},
  sinkData: () => {},
  trackWithDefaultStage: () => 0
};

const getExperimentEngineInstance = () => {
  if (typeof window !== "undefined") {
    return ExperimentEngine.getInstance({
      // Library implements debounce and sinks batches of tracking data.
      sinkUrl: `${window?.__GLOBAL_CONTEXT__?.apiPrefix || ""}/track/sink`,
      clientsidePayload: (typeof window !== "undefined" && window?.__GLOBAL_CONTEXT__?.clientsidePayload) || "",
      sinkTimeoutMs: 500
    });
  } else {
    return etV2Fallback;
  }
};

/**
 * @deprecated
 * Deprecated in favour of the trackWithDefaultStage method.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 */
export const track = (hashedTagname: string) => {
  // reading variant from et daemon on the server to render correct version on frontend during SSR
  if (process.env.BUILD_TARGET === "server") {
    return getAsyncRequestContext()?.request.et?.trackExperiment(hashedTagname) || 0;
  }

  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking experiment", hashedTagname);
  watchEt.dispatchName(hashedTagname);

  return experimentEngineInstance.track(hashedTagname);
};

export const stage = (hashedTagname: string, id: number) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking stage", id, "for experiment", hashedTagname);
  watchEt.log([
    {
      type: "stage",
      name: hashedTagname,
      value: id
    }
  ]);

  return experimentEngineInstance.stage(hashedTagname, id);
};

export const trackWithDefaultStage = (hashedTagname: string, defaultId: number) => {
  if (process.env.BUILD_TARGET === "server") {
    const et = getAsyncRequestContext()?.request.et;

    et?.trackExperimentStage(hashedTagname, defaultId);

    return et?.trackExperiment(hashedTagname) || 0;
  }

  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking experiment", hashedTagname, " with default stage ", defaultId);

  watchEt.log([
    {
      type: "stage",
      name: hashedTagname,
      value: defaultId
    }
  ]);

  experimentEngineInstance.stage(hashedTagname, defaultId);

  watchEt.dispatchName(hashedTagname);

  return experimentEngineInstance.track(hashedTagname);
};

export const customGoal = (hashedTagname: string, goal: number) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking custom goal", goal, "for experiment", hashedTagname);
  watchEt.log([
    {
      type: "customGoal",
      name: hashedTagname,
      value: goal
    }
  ]);

  return experimentEngineInstance.customGoal(hashedTagname, goal);
};

export const goal = (goalName: string) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking goal", goalName);
  watchEt.log([
    {
      type: "goal",
      name: goalName
    }
  ]);

  return experimentEngineInstance.goal(goalName);
};

export const goalWithValue = (goalName: string, value: number) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Tracking goal with value", goalName, "with value", value);
  watchEt.log([
    {
      type: "goalWithValue",
      name: goalName,
      value
    }
  ]);

  return experimentEngineInstance.goalWithValue(goalName, value);
};

export const updateStateBlobAndVariants = (clientsidePayload: string) => {
  const experimentEngineInstance = getExperimentEngineInstance();

  debug("Updating state blob and variants with clientside payload", clientsidePayload);

  return experimentEngineInstance.updateStateBlobAndVariants(clientsidePayload);
};

export const sinkData = () => {
  const experimentEngineInstance = getExperimentEngineInstance();
  return experimentEngineInstance.sinkData();
};
