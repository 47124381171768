import React, { memo, useRef } from "react";
import FareCardDesktop from "../../FlightCard/components/FareCard.desktop";
import { t } from "@bookingcom/lingojs-core";
import { Carousel, Divider, Grid } from "@bookingcom/bui-react";
import { UIFlightData } from "@flights/types";
import { SkeletonFlightCard } from "../../Skeleton/SkeletonFlightCard";
import { useI18n } from "@bookingcom/lingojs-react";
import { convertFlightToFare } from "../utils";
import useMeasureBrandedFaresWaitTime from "../hooks/useMeasureBrandedFaresWaitTime";
import useBrandedFares from "../hooks/useBrandedFares";
import Frame from "components/elements/Frame";
import useTrackFirstFarePriceMismatch from "components/elements/FareSelector/hooks/useTrackFirstFarePriceMismatch";
import useEqualRowHeight from "hooks/useEqualRowHeight";
import flights_ace_web_flexibilty_sr_page from "utils/experiments/ace/flights_ace_web_flexibilty_sr_page";
import TicketTypeSearchResultCard from "components/elements/FlightCard/components/TicketTypeSearchResultCard";
import styles from "./FareSelector.desktop.module.css";
import useCFARAncillary from "hooks/useCFARAncillary";
import useBookingWindowFromSearch from "hooks/useBookingWindowFromSearch";
import { trackExperiment } from "utils/et";
import flights_apex_web_expand_bf_on_sr from "utils/experiments/apex/flights_apex_web_expand_bf_on_sr";

type Props = {
  flight: UIFlightData;
};

const _FareSelectorDesktop = ({ flight }: Props) => {
  const i18n = useI18n();
  const { fetchStatus, brandedFareOffers } = useBrandedFares(flight.token);
  const hasBrandedFareOffers = !!brandedFareOffers && brandedFareOffers?.length > 0;
  const hasPrefetchedBrandedFareOffers =
    !flight.requestableBrandedFares && !!flight.brandedFareOffers && flight.brandedFareOffers?.length > 0;
  const containerRef = useRef<HTMLDivElement>(null);
  const hasFlexTicket = !!flight.ancillaries?.flexibleTicket;
  const hasCfarTicket = !!flight.ancillaries?.cancelForAnyReason;
  const isFlexibilitySrVariant = !!flights_ace_web_flexibilty_sr_page.variant();
  const { isCfarTestMarket, bookWindowDays } = useCFARAncillary(useBookingWindowFromSearch());

  useEqualRowHeight(containerRef, "data-fare-card-row", [brandedFareOffers, fetchStatus]);
  useMeasureBrandedFaresWaitTime(fetchStatus, hasBrandedFareOffers);
  useTrackFirstFarePriceMismatch(flight, brandedFareOffers);

  return (
    <div ref={containerRef} id="flights-fare-selector">
      <Divider />
      <Frame
        direction="row"
        m={4}
        attributes={{
          style: { maxWidth: !!trackExperiment("flights_web_ddot_sr_wide_page_layout") ? undefined : "682px" }
        }}
      >
        {hasBrandedFareOffers ? (
          <div style={{ width: "100%" }}>
            <Carousel
              size={!!trackExperiment("flights_web_ddot_sr_wide_page_layout") ? "medium" : "larger"}
              nextButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_next_button_aria_label"))}
              previousButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_previous_button_aria_label"))}
            >
              {brandedFareOffers.map((brandedFareOffer, i) => (
                <FareCardDesktop
                  key={brandedFareOffer.token}
                  baseOffer={flight}
                  fareOffer={brandedFareOffer}
                  index={i}
                  numberOfOffers={brandedFareOffers.length}
                  ancillaries={flight.ancillaries}
                />
              ))}
            </Carousel>
          </div>
        ) : hasPrefetchedBrandedFareOffers ? (
          <div style={{ width: "100%" }}>
            <Carousel
              size={!!trackExperiment("flights_web_ddot_sr_wide_page_layout") ? "medium" : "larger"}
              nextButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_next_button_aria_label"))}
              previousButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_previous_button_aria_label"))}
            >
              {flight.brandedFareOffers?.map((brandedFareOffer, i) => (
                <FareCardDesktop
                  key={brandedFareOffer.token}
                  baseOffer={flight}
                  fareOffer={brandedFareOffer}
                  index={i}
                  numberOfOffers={flight.brandedFareOffers?.length}
                  ancillaries={flight.ancillaries}
                />
              ))}
            </Carousel>
          </div>
        ) : isFlexibilitySrVariant && !flight.requestableBrandedFares ? (
          <div style={{ width: "100%" }}>
            <Carousel
              size="larger"
              nextButtonAriaLabel={i18n.trans(t("a11y_flights_ace_sr_next_tt_chevron"))}
              previousButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_previous_button_aria_label"))}
            >
              <Carousel.Item className={styles.carouselItem}>
                <TicketTypeSearchResultCard
                  key={0}
                  ticketType="standard"
                  fareOffer={convertFlightToFare(flight, i18n)}
                  baseOffer={flight}
                  loadingStatus={fetchStatus}
                />
              </Carousel.Item>
              {hasFlexTicket ? (
                <Carousel.Item className={styles.carouselItem}>
                  <TicketTypeSearchResultCard
                    key={1}
                    ticketType="flexible"
                    fareOffer={convertFlightToFare(flight, i18n)}
                    baseOffer={flight}
                    loadingStatus={fetchStatus}
                  />
                </Carousel.Item>
              ) : null}

              {hasCfarTicket && isCfarTestMarket && bookWindowDays && bookWindowDays <= 90 ? (
                <Carousel.Item className={styles.carouselItem}>
                  <TicketTypeSearchResultCard
                    key={2}
                    ticketType="cfar"
                    fareOffer={convertFlightToFare(flight, i18n)}
                    baseOffer={flight}
                    loadingStatus={fetchStatus}
                  />
                </Carousel.Item>
              ) : null}
              {fetchStatus === "loading" && (
                <Grid.Column size={4}>
                  <SkeletonFlightCard shorterCard={!!flights_apex_web_expand_bf_on_sr.trackWithDefaultStage()} />
                </Grid.Column>
              )}
            </Carousel>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <Grid>
              <Grid.Column size={4}>
                <FareCardDesktop
                  fareOffer={convertFlightToFare(flight, i18n)}
                  baseOffer={flight}
                  loadingStatus={fetchStatus}
                />
              </Grid.Column>
              {fetchStatus === "loading" && (
                <Grid.Column size={4}>
                  <SkeletonFlightCard shorterCard={!!flights_apex_web_expand_bf_on_sr.trackWithDefaultStage()} />
                </Grid.Column>
              )}
            </Grid>
          </div>
        )}
      </Frame>
    </div>
  );
};

export const FareSelectorDesktop = memo(_FareSelectorDesktop);
