import React, { Fragment, useCallback, useContext, useMemo } from "react";
import { t } from "@bookingcom/lingojs-core";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { useOccupancyCabinClass } from "../OccupancyCabinClass/index.js";
import { useSearchType } from "../SearchType/index.js";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { ArrowLongRightIcon, ArrowsLeftRightIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Icon } from "@bookingcom/bui-react";
const useOverview = () => {
    const { i18n, segments: allSegments } = useContext(SearchBoxContext);
    const { travellers, cabinClassName } = useOccupancyCabinClass();
    const { searchTypeName, searchType } = useSearchType();
    const { formats } = useFormatDateTime(i18n);
    const airportName = useCallback((loc) => loc
        .map((l) => {
        return searchType === "MULTISTOP"
            ? l.code
            : [l.type === "AIRPORT" && l.code, l["cityName"] || l.name].filter(Boolean).join(" ");
    })
        .join(", "), [searchType]);
    const airports = useMemo(() => {
        const isFlyAnywhere = window?.location?.pathname?.startsWith("/fly-anywhere");
        return allSegments.map((_) => [
            airportName(_.from),
            isFlyAnywhere ? i18n.trans(t("flights_search_destination_anywhere")) : airportName(_.to)
        ]);
    }, [airportName, i18n, allSegments]);
    const airportList = useMemo(() => {
        return airports.reduce((result, current, idx) => {
            if (idx == 0) {
                result.push([current[0], current[1]].filter(Boolean));
                return result.filter(Boolean);
            }
            const prev = result?.[result.length - 1];
            if (current?.[0] === prev?.[prev.length - 1]) {
                result.splice(result.length - 1, 1, [...prev, current[1]].filter(Boolean));
            }
            else {
                result.push([current[0], current[1]].filter(Boolean));
            }
            return result.filter(Boolean);
        }, []);
    }, [airports]);
    const cabin = useMemo(() => cabinClassName, [cabinClassName]);
    const dates = useMemo(() => {
        const firstSegmentDepartureDate = allSegments[0].departureDate;
        const lastSegmentReturnDate = allSegments[allSegments.length - 1].returnDate ||
            (searchType === "MULTISTOP" && allSegments[allSegments.length - 1].departureDate);
        return [firstSegmentDepartureDate, lastSegmentReturnDate]
            .filter(Boolean)
            .map((_) => formats.flightDate(_))
            .join(" - ");
    }, [allSegments, formats, searchType]);
    const travellersText = useMemo(() => i18n.trans(t("flights_search_passenger_count_mix", {
        num_exception: travellers,
        variables: { num_travellers: travellers }
    })), [travellers]);
    const ariaLabel = i18n.trans(t("a11y_flights_search_on_sr_edit"));
    const arrow = (React.createElement(Icon, { attributes: { style: { verticalAlign: "middle" } }, svg: searchType === "ROUNDTRIP" ? ArrowsLeftRightIcon : ArrowLongRightIcon }));
    const lineOne = useMemo(() => airportList.flat().length
        ? airportList.map((fromTo, i) => (React.createElement(Fragment, { key: i },
            i !== 0 ? " · " : "",
            fromTo.map((_, idx) => (React.createElement(Fragment, { key: idx },
                idx !== 0 ? " " : "",
                idx !== 0 ? arrow : "",
                idx !== 0 ? " " : "",
                _))))))
        : searchTypeName, [airportList]);
    const lineTwo = useMemo(() => {
        return [dates, travellersText, cabin].filter(Boolean).join(" · ");
    }, [dates, travellersText, cabin]);
    return {
        searchTypeName,
        lineOne,
        lineTwo,
        ariaLabel
    };
};
export default useOverview;
