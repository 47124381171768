import { useEffect, useState } from "react";

import { UIXComponentType, UIXNearbyDestination, UIXNearbyDestinationsListData } from "../types";
import { useSearchParamsData } from "./useSearchParamsData";
import useContextName from "hooks/useContextName";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../store";

import checkIsPastBooking from "utils/past-booking";
import useIsPbFlightDetails from "../hooks/useIsPbFlightDetails";
import { getNearbyDestinationsList } from "cross-sell/api/getNearbyList";
import useEvents from "../../hooks/useEvents";

export const useNearbyListCall = (componentType: UIXComponentType) => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const { order } = store.order;
  const data = useSearchParamsData(componentType, 3);
  const contextName = useContextName();
  const isPastBooking = checkIsPastBooking(order);
  const isPbFlightDetails = useIsPbFlightDetails();
  const [destinations, setDestinations] = useState<UIXNearbyDestination[] | undefined>();
  const [destinationsLoading, setDestinationsLoading] = useState(false);
  const { jsError } = useEvents();

  useEffect(() => {
    if (!isPbFlightDetails && !isPastBooking && data) {
      const headers = {
        "X-Flights-Context-Name": contextName
      };
      setDestinationsLoading(true);
      getNearbyDestinationsList(data, headers || {})
        .then((res: UIXNearbyDestinationsListData) => {
          setDestinations(res.destinations);
        })
        .catch(jsError)
        .finally(() => {
          setDestinationsLoading(false);
        });
    }
  }, [contextName, data, isPbFlightDetails, isPastBooking, jsError]);

  return { destinations, destinationsLoading };
};
