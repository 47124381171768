import React, { useEffect } from "react";
import ErrorBoundary from "../components/elements/ErrorBoundary";
import GeniusBanner from "cross-sell/components/GeniusBanner";
import MobileCardsList from "cross-sell/components/MobileCardsList";
import { UIXComponentTypes } from "cross-sell/types";
import { XSellProps } from "./types";
import DesktopCardsList from "./components/DesktopCardsList";
import ConnectorCard from "./components/ConnectorCard";
import CrossSellLoadingScreen from "./components/CrossSellLoadingScreen";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../store";
import useRouteName from "hooks/useRouteName";
import useUserAgent from "hooks/useUserAgent";
import { trackExperimentStage } from "utils/et";
import { isRoundTrip } from "./utils";
import { CSXPLoader, Props as CrossSellProps } from "cross-sell/components/CSXPLoader";
import NearbyList from "./components/NearbyList";
import { UISearchSegment } from "store/search/state";
import { UILocation } from "@flights/types";

function CrossSellFunc(props: XSellProps) {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();
  const { crossSell } = store.crossSell;
  const { order } = store.order;
  const { userInfo } = store.user;
  const isARoundTrip = order?.airOrder.flightSegments ? isRoundTrip(order?.airOrder.flightSegments) : false;

  const getComponent = (
    component: UIXComponentTypes,
    isModal?: boolean,
    setShowSheet?: (a: boolean) => void,
    variant?: string
  ) => {
    switch (component) {
      case "GeniusBanner":
        return <GeniusBanner />;
      case "MobileCardsList":
        return <MobileCardsList />;
      case "DesktopCardsList":
        return <DesktopCardsList />;
      case "ConnectorCard":
        return <ConnectorCard />;
      case "NearbyList":
        return <NearbyList />;
      case "LoadingScreen":
        return <CrossSellLoadingScreen />;
      case "ExposureCrossSell":
        return (
          <div id="cross-sell-loader">
            {buildCrossSellProps(routeName, isModal, setShowSheet, variant) ? (
              <CSXPLoader {...buildCrossSellProps(routeName, isModal, setShowSheet, variant)} />
            ) : null}
          </div>
        );
      default:
        return undefined;
    }
  };

  const buildCrossSellProps = (
    routeName: string,
    isModal?: boolean,
    setShowSheet?: (a: boolean) => void,
    variant?: string
  ): CrossSellProps | null => {
    if (!order || !order.orderId || routeName === "checkout3") {
      const { searchSegments, adults, type, children } = store.search;
      return {
        flightContext: {
          adults,
          children,
          trip_type: type,
          searchSegments: searchSegments.map((segment: UISearchSegment) => ({
            from: segment.from.map((from: UILocation) => (from.type === "AIRPORT" ? from.city : from.code)),
            to: segment.to.map((to: UILocation) => (to.type === "AIRPORT" ? to.city : to.code)),
            departureDate: segment.departureDate,
            returnDate: segment.returnDate
          }))
        },
        isModal: isModal || false,
        setShowSheet: setShowSheet || (() => {}),
        variant: variant || undefined
      };
    }
    return {
      currencyCode: order?.orderCurrency,
      reserveOrderId: order.orderId,
      isModal: isModal || false,
      setShowSheet: setShowSheet || (() => {}),
      variant: variant || undefined
    };
  };

  useEffect(() => {
    if (crossSell?.accommodations.length && userInfo) {
      trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 1);
      if (routeName === "confirmation") {
        trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 2);
      } else if (routeName === "pb-order-details") {
        trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 3);
      }
      if (order?.passengers.length !== 1) {
        trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 4);
      }
      if (!isARoundTrip) {
        trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 5);
      } else {
        trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 6);
      }
      if (order?.salesInfo.country?.toLowerCase() === "us") {
        trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 7);
      }
      if (order?.salesInfo.country?.toLowerCase() === "gb" || order?.salesInfo.country?.toLowerCase() === "de") {
        trackExperimentStage("flights_web_cross_sell_pay_with_wallet", 8);
      }
    }
    if (crossSell?.accommodations.length && order?.airOrder.flightSegments && !isARoundTrip) {
      trackExperimentStage("flights_web_cross_sell_flights_to_flights", 1);
      if (routeName === "confirmation") {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 2);
      } else if (routeName === "pb-order-details") {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 3);
      }
      if (order?.passengers.length !== 1) {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 4);
      } else {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 5);
      }
      if (!isMobile) {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 8);
      } else {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 9);
      }
      if (order?.airOrder.flightSegments.length > 1) {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 6); // Multi leg flight
      } else {
        trackExperimentStage("flights_web_cross_sell_flights_to_flights", 7); // single city flight
      }
    }

    if (order?.airOrder.flightSegments) {
      trackExperimentStage("flights_web_cross_sell_nearby_destinations", 1);
      if (routeName === "confirmation") {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 2);
      } else if (routeName === "pb-order-details") {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 3);
      }
      if (!isMobile) {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 4);
      } else {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 5);
      }
      if (order?.passengers.length !== 1) {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 6);
      } else {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 7);
      }
      if (!isARoundTrip) {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 8);
      } else {
        trackExperimentStage("flights_web_cross_sell_nearby_destinations", 9);
      }
    }
  }, [crossSell, order, routeName, isARoundTrip, userInfo, isMobile]);

  return (
    <ErrorBoundary quiet={true}>
      <div>{getComponent(props.component, props.isModal, props.setShowSheet, props.variant)}</div>
    </ErrorBoundary>
  );
}

export const XSell = React.memo(CrossSellFunc);
