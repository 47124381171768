import { __deprecated__createTrackingUtils } from "utils/experiments/index";

const trackPriorityRoutesComponent = __deprecated__createTrackingUtils({
  name: "flights_web_remote_component_seo_interlinking",
  stages: {
    main: 1, // main
    desktop: 2,
    mobile: 3,
    desktop_in_viewport: 4,
    mobile_in_viewport: 5
  },
  goals: {
    fallback_shown: 1, // Fallback component shown because of some fetching issue
    lp_open_desktop: 2, // User navigated to one the landing pages on desktop
    lp_open_mobile: 3 // User navigated to one the landing pages on mdot
  },
  goalsWithValue: []
});

export function trackBasicPriorityRoutesStages(isMobile: boolean) {
  trackPriorityRoutesComponent.stages.main();

  isMobile ? trackPriorityRoutesComponent.stages.mobile() : trackPriorityRoutesComponent.stages.desktop();
}

export default trackPriorityRoutesComponent;
