/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import { UIBrandedFareInfo, UIIncludedProductBySegmentCompressed } from "@flights/types";
import React, { useMemo } from "react";
import { getFeaturesToRender } from "../../CheckoutFareInner/utils/features";
import { Icon } from "@bookingcom/bui-react";
import { includedBaggageIcons } from "constants/icons";
import useUserAgent from "hooks/useUserAgent";
import { getProductIconsIncludedInAllSegments } from "../../../../utils/includedProducts";
import Frame from "components/elements/Frame";
import styles from "./FareFeaturesIcons.module.css";
import flights_apex_web_move_baggage_features_to_server from "utils/experiments/apex/flights_apex_web_move_baggage_features_to_server";
import flights_apex_web_bf_on_mdot from "utils/experiments/apex/flights_apex_web_bf_on_mdot";
import flights_apex_web_expand_bf_on_sr from "utils/experiments/apex/flights_apex_web_expand_bf_on_sr";

type FareFeaturesIconsProps = {
  includedProducts?: UIIncludedProductBySegmentCompressed;
  brandedFareInfo: UIBrandedFareInfo;
  limit?: number;
};

const FareFeaturesIcons = (props: FareFeaturesIconsProps) => {
  const { includedProducts, brandedFareInfo, limit } = props;
  const { includedFeatures } = useMemo(() => getFeaturesToRender(brandedFareInfo), [brandedFareInfo]);

  const { isMobile } = useUserAgent();
  const inExpandFareOnSr = !isMobile && !!flights_apex_web_expand_bf_on_sr.trackWithDefaultStage();

  const includedFeaturesIcons = includedFeatures
    ? includedFeatures.map((feature) => ({ icon: feature.icon, name: feature.name }))
    : [];

  const baggageIcons = useMemo(() => {
    if (!includedProducts || !!flights_apex_web_move_baggage_features_to_server.variant()) {
      return [];
    }

    return getProductIconsIncludedInAllSegments(includedProducts).map((product) => ({
      name: product.luggageType,
      icon: includedBaggageIcons[product.luggageType]
    }));
  }, [includedProducts]);

  const fareFeaturesIcons = [...baggageIcons, ...includedFeaturesIcons]
    .slice(0, limit)
    .map(({ icon, name }) => <Icon key={name} svg={icon} size={inExpandFareOnSr ? "medium" : "large"} />);

  if (!fareFeaturesIcons.length) {
    return null;
  }

  return (
    <Frame
      attributes={{ "data-testid": "FareFeaturesIcons" }}
      className={!!flights_apex_web_bf_on_mdot.trackWithDefaultStage() && isMobile && styles.iconWrapper}
    >
      {fareFeaturesIcons}
    </Frame>
  );
};

export default FareFeaturesIcons;
