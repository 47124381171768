/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useMemo, useState, useEffect } from "react";
import { Button } from "@bookingcom/bui-react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import { useXsellActionsCall } from "../../hooks/useXsellActionsCall";
import { UIXAccommodation } from "../../types";
import MobileAccommodationCard from "../common/MobileAccommodationCard";

import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { useEventsTracking } from "../../hooks/useEventsTracking";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { useClickHandlers } from "../../hooks/useClickHandlers";
import useLoader from "../../hooks/useLoader";
import styles from "./MobileCardsList.module.css";

import {
  TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
  TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_GOALS,
  TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_STAGES
} from "constants/experiments";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";

const MobileCardsList = () => {
  const [currentElement, setCurrentElement] = useState<any>(null);

  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const i18n = useI18n();
  const { crossSell: carouselData, isLoading } = store.crossSell;
  const loader = useLoader("mobileCards");
  const isCardsListInViewport = useIsInViewport(currentElement, undefined, { threshold: 0.33 });
  const { handleButtonClick, handleCardClick } = useClickHandlers("mList");

  useXsellActionsCall("mList", 3);
  useEventsTracking(isCardsListInViewport, "mList");

  const setRefEl = (element: HTMLElement | null) => {
    setCurrentElement(element);
  };

  useEffect(() => {
    if (!isLoading && trackExperiment(TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF) === 0) {
      if (carouselData?.accommodations.length && carouselData?.accommodations.length > 0) {
        trackCustomGoal(
          TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
          TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_GOALS.VALID_RESPONSE_RECEIVED
        );
        carouselData?.accommodations.forEach((accommodation: UIXAccommodation) => {
          if (accommodation.mealplanDescription) {
            trackExperimentStage(
              TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
              TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_STAGES.MEAL_PLAN
            );
          }
          if (accommodation.hasFreeCancellation) {
            trackExperimentStage(
              TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
              TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_STAGES.FREE_CANCELLATION
            );
          }
          if (accommodation.distanceFromCentre) {
            trackExperimentStage(
              TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
              TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_STAGES.DISTANCE_FROM_CENTRE
            );
          }
        });
      }
      if (carouselData?.accommodations && carouselData?.accommodations.length === 0) {
        trackCustomGoal(
          TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
          TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_GOALS.ERROR_OR_EMPTY_RESPONSE_RECEIVED
        );
      }
    }
  }, [carouselData?.accommodations, isLoading]);

  /* istanbul ignore next */
  const CTA_TEXT: string = useMemo(() => {
    return i18n.trans(t("flights_confirmation_genius_see_action"));
  }, [i18n]);

  if (isLoading) {
    return loader;
  }

  return carouselData?.accommodations.length ? (
    <div ref={setRefEl} className={styles.refDiv}>
      {carouselData?.accommodations.map((accommodation: UIXAccommodation, index: number) => (
        <div key={index} className={styles.card} data-testid={`card-${index}`}>
          <MobileAccommodationCard
            onCardClick={() => {
              handleCardClick(accommodation.id, index, accommodation.recResultsId, accommodation.propertyTarget.target);
            }}
            accommodation={accommodation}
            index={index}
            searchParams={carouselData.searchParams}
          />
        </div>
      ))}
      {carouselData?.searchResults.target ? (
        <Button
          attributes={{
            "data-testid": "carousel-redirect-btn"
          }}
          size="large"
          wide={{
            s: true,
            m: false
          }}
          onClick={() => {
            handleButtonClick(carouselData.searchResults.target, CTA_TEXT);
          }}
          variant="secondary"
        >
          {CTA_TEXT}
        </Button>
      ) : null}
    </div>
  ) : null;
};

export default MobileCardsList;
