import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { useCallback, useContext, useMemo } from "react";
import { SEARCH_TYPE_MAP, SEARCH_TYPE_EXTENDED_MAP } from "../../utils/constants.js";
const useSearchType = () => {
    const { i18n, searchType, setSearchType, trackV2 } = useContext(SearchBoxContext);
    const searchTypeOptions = Object.keys(SEARCH_TYPE_MAP).map((typeName) => ({
        value: typeName,
        label: i18n.trans(SEARCH_TYPE_MAP[typeName]),
        text: i18n.trans(SEARCH_TYPE_MAP[typeName]),
        id: `search_type_option_${typeName}`
    }), []);
    const searchTypeExtendedOptions = Object.keys(SEARCH_TYPE_EXTENDED_MAP).map((typeName) => ({
        value: typeName,
        label: i18n.trans(SEARCH_TYPE_EXTENDED_MAP[typeName]),
        text: i18n.trans(SEARCH_TYPE_EXTENDED_MAP[typeName]),
        id: `search_type_option_${typeName}`
    }), []);
    const searchTypeName = useMemo(() => i18n.trans(SEARCH_TYPE_MAP[searchType]), [searchType, i18n]);
    const searchTypeNameExtended = useMemo(() => i18n.trans(SEARCH_TYPE_EXTENDED_MAP[searchType]), [searchType, i18n]);
    const onSearchTypeChange = useCallback((value) => {
        trackV2("select_journey_type", value);
        setSearchType(value);
    }, [setSearchType, trackV2]);
    return {
        searchType,
        searchTypeName,
        searchTypeNameExtended,
        searchTypeOptions,
        searchTypeExtendedOptions,
        onSearchTypeChange
    };
};
export default useSearchType;
