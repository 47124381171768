import React, { useMemo } from "react";
import styles from "./ErrorMessage.module.css";
import { Icon } from "@bookingcom/bui-react";
import { WarningIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
const ErrorInline = (props) => {
    const { text, attributes, id } = props;
    const { className, ...restAttributes } = attributes || {};
    const rootStyles = useMemo(() => {
        return [styles.inline, className].filter(Boolean).join(" ");
    }, [className]);
    return (React.createElement("div", { "data-ui-name-message": true, role: "alert", id: id, ...restAttributes, className: rootStyles }, text));
};
const ErrorTooltip = (props) => {
    const { text, attributes, verticalPosition = "bottom", id, isNewUiExp } = props;
    const { className, ...restAttributes } = attributes || {};
    const rootStyles = useMemo(() => {
        return [
            styles.root,
            className,
            verticalPosition == "top" && styles.rootTop,
            verticalPosition == "bottom" && styles.rootBottom
        ]
            .filter(Boolean)
            .join(" ");
    }, [verticalPosition, className]);
    const wrapperStyles = useMemo(() => {
        return [
            styles.wrapper,
            isNewUiExp && styles.wrapperNew,
            verticalPosition == "top" && styles.wrapperTop,
            verticalPosition == "bottom" && styles.wrapperBottom
        ]
            .filter(Boolean)
            .join(" ");
    }, [verticalPosition]);
    return (React.createElement("div", { id: id, role: "alert", "data-ui-name-message": true, className: rootStyles },
        React.createElement("div", { className: wrapperStyles },
            React.createElement("div", { className: `${styles.content} ${isNewUiExp ? styles.contentNew : ""}`, ...restAttributes },
                isNewUiExp ? React.createElement(Icon, { svg: WarningIcon, size: "medium" }) : null,
                text))));
};
const ShellErrorMessage = (props) => {
    return props.variant === "tooltip" ? React.createElement(ErrorTooltip, { ...props }) : React.createElement(ErrorInline, { ...props });
};
export default ShellErrorMessage;
