import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { getSearchBoxGAData, getSearchBoxTrackingObj } from "../../utils/trackingHelper.js";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { ANYWHERE } from "../../utils/constants.js";
const useAutoComplete = (props) => {
    const abortController = useRef();
    const timer = useRef();
    const [q, setQ] = useState("");
    const { segmentIndex, type } = props;
    const { autoCompleteBaseURL, i18n, lang = "en", searchType, segments, setSegmentFrom, setSegmentTo, trackV2, trackLandingGoal, onTrackSBExperimentCustomGoal, disableAutocompleteMultiSelect, multiSelectVariant, multiSelectName, isFlyAnywhereExp, isMobile, trackFlyAnywhereWww, trackFlyAnywhereMdot } = useContext(SearchBoxContext);
    const isMultiStop = searchType === "MULTISTOP";
    const provider = typeof window !== "undefined" && window.location && window.location.search.indexOf("ac=1") >= 0 ? "ours" : "brick";
    const urlPath = `/api/autocomplete/${lang}?q=@@`;
    const urlPathETG = `/api/autocomplete-etg/${lang}?q=@@`;
    const setSegment = useCallback((locations) => {
        const trackingObj = getSearchBoxTrackingObj(q, locations, segmentIndex);
        const gaData = getSearchBoxGAData(locations);
        if (type === "from") {
            if (trackLandingGoal && locations.length) {
                onTrackSBExperimentCustomGoal({
                    name: "recp_flightstech_webfunnel_flightssearch_recpipeline_web",
                    customGoal: 1
                });
            }
            trackV2("select_origin", trackingObj, gaData);
            setSegmentFrom(locations, segmentIndex);
        }
        else {
            trackV2("select_destination", trackingObj, gaData);
            setSegmentTo(locations, segmentIndex);
        }
    }, [type, segmentIndex, setSegmentFrom, setSegmentTo, q, trackV2, trackLandingGoal, onTrackSBExperimentCustomGoal]);
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const debounce = useCallback((func) => {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (...args) => {
            const later = () => {
                if (timer.current)
                    clearTimeout(timer.current);
                func(...args);
            };
            if (timer.current)
                clearTimeout(timer.current);
            timer.current = setTimeout(later, 400);
        };
    }, []);
    const initialState = useMemo(() => {
        return { loading: false, error: false, success: false };
    }, []);
    const [result, setResult] = useState([]);
    const [state, setState] = useState(initialState);
    const url = useMemo(() => {
        if (autoCompleteBaseURL) {
            return provider === "brick"
                ? `${autoCompleteBaseURL.url}${urlPath}&accessToken=${autoCompleteBaseURL.accessToken}`
                : `${autoCompleteBaseURL.url}${urlPathETG}&accessToken=${autoCompleteBaseURL.accessToken}`;
        }
        else {
            return provider === "brick" ? `${urlPath}` : `${urlPathETG}`;
        }
    }, [autoCompleteBaseURL, provider, urlPath, urlPathETG]);
    const search = useCallback(async (q) => {
        if (q.length < 2)
            return;
        setQ(q);
        setState({ ...initialState, loading: true });
        trackV2(type === "from" ? "type_origin" : "type_destination ", q);
        try {
            const searchString = encodeURIComponent(q.trim());
            if (abortController.current)
                abortController.current.abort();
            abortController.current = new AbortController();
            const results = await fetch(url.replace("@@", searchString), {
                credentials: "include",
                headers: { Accept: "application/json" },
                signal: abortController.current?.signal
            })
                .then((r) => r.json())
                .then((r) => r.map((_r, idx) => {
                _r["autoCompleteResultIndex"] = idx;
                return _r;
            }));
            setResult(results);
            setState({ ...initialState, success: true });
        }
        catch (err) {
            setResult([]);
            const error = err.name !== "AbortError";
            if (error)
                setState({ ...initialState, error });
        }
    }, [url, setState, setResult, initialState, trackV2, type]);
    const selectedLocations = useMemo(() => {
        return segments?.[segmentIndex]?.[type] || [];
    }, [type, segmentIndex, segments]);
    const removeLocationByIndex = useCallback((idx) => {
        if (idx < 0)
            return;
        const result = [...selectedLocations];
        result.splice(idx, 1);
        setSegment(result);
    }, [setSegment, selectedLocations]);
    const addLocation = useCallback((loc) => {
        const result = selectedLocations.filter((s) => !((s.code === loc.code && s.type === loc.type) || (loc.type === "CITY" && s["parent"] === loc.code)));
        const newSegment = loc.type === ANYWHERE ? [loc] : [...result.filter((item) => item.type !== ANYWHERE), loc];
        setSegment(newSegment);
    }, [setSegment, selectedLocations]);
    const setLocation = useCallback((loc) => {
        setSegment([loc]);
    }, [setSegment]);
    const removeLocation = useCallback((loc) => {
        const result = selectedLocations.filter((s) => s.code !== loc.code);
        setSegment([...result]);
    }, [setSegment, selectedLocations]);
    return {
        addLocation,
        setLocation,
        i18n,
        isMultiStop,
        removeLocation,
        removeLocationByIndex,
        result,
        search: debounce(search),
        selectedLocations,
        state,
        trackV2,
        disableAutocompleteMultiSelect,
        multiSelectVariant,
        multiSelectName,
        onTrackSBExperimentCustomGoal,
        searchType,
        isFlyAnywhereExp,
        isMobile,
        trackFlyAnywhereWww,
        trackFlyAnywhereMdot
    };
};
export default useAutoComplete;
