import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { useCallback, useContext, useMemo } from "react";
import { CABIN_CLASS_MAP, CABIN_CLASS_FILTER_OPTIONS } from "../../utils/constants.js";
import { t } from "@bookingcom/lingojs-core";
export default function () {
    const { i18n, cabinClass, setCabinClass: _setCabinClass, trackV2, isNewUiExp } = useContext(SearchBoxContext);
    const setCabinClass = useCallback((name) => {
        trackV2("select_cabin_class", name);
        _setCabinClass(name);
    }, [_setCabinClass, trackV2]);
    const cabinClassOptions = useMemo(() => {
        return CABIN_CLASS_FILTER_OPTIONS.map((name) => ({
            value: name,
            text: i18n.trans(CABIN_CLASS_MAP[name]),
            label: i18n.trans(CABIN_CLASS_MAP[name]),
            name: "sr_cabin_class"
        }));
    }, [i18n]);
    const cabinClassTitle = i18n.trans(t("flights_cabin_class_name"));
    return {
        cabinClass,
        cabinClassOptions,
        cabinClassTitle,
        setCabinClass,
        trackV2,
        isNewUiExp
    };
}
