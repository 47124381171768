import { useCallback, useContext, useMemo } from "react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { t } from "@bookingcom/lingojs-core";
import { parseDateISO8601, useDateFormat, useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { validateSegment } from "../../utils/validator.js";
export default function (props) {
    const { segmentIndex } = props;
    const { searchType, i18n, segments, setDate, trackV2, isSubmitted, dateFNSLocale, isFareCalendarEnabled, isFareCalendarUIEnabled, onTrackSBExperiment, onGetSBExperimentVariant } = useContext(SearchBoxContext);
    const { formats } = useFormatDateTime(i18n);
    const { dateFormat } = useDateFormat(dateFNSLocale);
    const isRoundTrip = searchType === "ROUNDTRIP";
    const isMultiStop = searchType === "MULTISTOP";
    const segment = segments[segmentIndex];
    const { departureDate, returnDate } = segment;
    const from = useMemo(() => {
        return departureDate ? parseDateISO8601(departureDate) : undefined;
    }, [departureDate]);
    const to = useMemo(() => {
        return returnDate ? parseDateISO8601(returnDate) : undefined;
    }, [returnDate]);
    const isSameYear = useCallback((d) => {
        return new Date().getFullYear() === d.getFullYear();
    }, []);
    const formatDate = useCallback((d, defaultText) => {
        let result = "";
        if (d) {
            result = isSameYear(d) ? formats.flightDateWeekday(d) : formats.flightShortDateWeekdayYear(d);
        }
        else if (defaultText) {
            result = defaultText;
        }
        return result;
    }, [formats, isSameYear]);
    const textPlaceholder = useMemo(() => {
        return isRoundTrip
            ? i18n.trans(t("flights_search_date_range", {
                variables: {
                    flight_date_1: i18n.trans(t("flights_search_depart_label")),
                    flight_date_2: i18n.trans(t("flights_search_return_label"))
                }
            }))
            : i18n.trans(t("flights_search_choose_depart_date"));
    }, [i18n, isRoundTrip]);
    const text = useMemo(() => {
        const fromText = formatDate(from, i18n.trans(t("flights_search_depart_label")));
        const toText = formatDate(to, i18n.trans(t("flights_search_return_label")));
        let result = undefined;
        if (isRoundTrip) {
            result =
                from || to
                    ? i18n.trans(t("flights_search_date_range", {
                        variables: { flight_date_1: fromText, flight_date_2: toText }
                    }))
                    : undefined;
        }
        else {
            result = from ? fromText : undefined;
        }
        return result;
    }, [i18n, isRoundTrip, from, to, formatDate]);
    const getTextNights = useCallback(({ from, to }) => {
        if (!isRoundTrip) {
            return from
                ? formatDate(from, i18n.trans(t("flights_search_depart_label")))
                : i18n.trans(t("flights_search_choose_depart_date"));
        }
        if (!from || !to) {
            const fromText = formatDate(from, i18n.trans(t("flights_search_depart_label")));
            const toText = formatDate(to, i18n.trans(t("flights_search_return_label")));
            return i18n.trans(t("flights_search_date_range", {
                variables: { flight_date_1: fromText, flight_date_2: toText }
            }));
        }
        const nightsDiff = Math.floor((to.getTime() - from.getTime()) / 864e5);
        return isRoundTrip && from && to
            ? i18n.trans(t("flights_search_dates_selected_nights", {
                variables: {
                    depart_date: String(formatDate(from)),
                    return_date: String(formatDate(to)),
                    num_night: nightsDiff,
                    num_exception: nightsDiff
                }
            }))
            : undefined;
    }, [i18n, isRoundTrip, formatDate]);
    const isDateSelected = useMemo(() => {
        return isRoundTrip ? !!from && !!to : !!from;
    }, [isRoundTrip, from, to]);
    const setFromTo = useCallback(({ from, to, fareRange }) => {
        if (isMultiStop) {
            const dates = new Array(segments.length).fill({
                from: undefined,
                to: undefined
            });
            dates[segmentIndex] = { from: from, to: to };
            for (let i = segmentIndex + 1; i < segments.length; i++)
                setDate(dates[i], i);
        }
        setDate({ from: from, to: to }, segmentIndex, fareRange);
        if (from && !to) {
            trackV2("select_calendar_inbound", {
                date: dateFormat(from, "MMM d, yyyy"),
                segment: segmentIndex
            });
        }
        if (to) {
            trackV2("select_calendar_outbound", {
                date: dateFormat(to, "MMM d, yyyy"),
                segment: segmentIndex
            });
        }
    }, [segmentIndex, setDate, segments, isMultiStop, trackV2, dateFormat]);
    const errorMessage = isSubmitted && !isDateSelected && !validateSegment(segment, segmentIndex, searchType)
        ? i18n.trans(t("flights_search_empty_dates"))
        : undefined;
    return {
        from,
        i18n,
        isDateSelected,
        isRoundTrip,
        isMultiStop,
        isSubmitted,
        setFromTo,
        text,
        getTextNights,
        textPlaceholder,
        to,
        trackV2,
        errorMessage,
        isFareCalendarEnabled,
        isFareCalendarUIEnabled,
        onTrackSBExperiment,
        onGetSBExperimentVariant
    };
}
