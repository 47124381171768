import React, { forwardRef, useMemo, useCallback } from "react";
import { Icon } from "@bookingcom/bui-react";
import { WarningIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import SharedIcon from "./Icon.js";
import ShellErrorMessage from "./ErrorMessage.js";
import styles from "./ShellWrapper.module.css";
const ShellWrapperWithRef = forwardRef(function ShellWrapper(props, ref) {
    const { border, children, className, clickThrough, errorId, errorMessage, errorPosition, errorVariant, fill, icon, isFocus, onClick, size, alignIcon, ...rest } = props;
    const rootStyles = useMemo(() => {
        return [
            className,
            styles.root,
            isFocus && styles.rootfocus,
            errorMessage && styles.rootError,
            fill && styles.rootFill,
            border && styles.rootBorder,
            size === "large" && styles.rootLarge
        ]
            .filter(Boolean)
            .join(" ");
    }, [className, errorMessage, isFocus, fill, border, size]);
    const handleOnClick = useCallback((e) => {
        if (clickThrough && clickThrough.current) {
            if (e.target === clickThrough.current)
                return;
            clickThrough.current.focus();
            clickThrough.current.click();
        }
        if (onClick)
            onClick(e);
    }, [onClick, clickThrough]);
    return (React.createElement("div", { ref: ref, onClick: handleOnClick, className: rootStyles, ...rest },
        React.createElement("div", null,
            React.createElement("div", { className: styles.wrapper },
                icon ? (React.createElement("div", { style: { alignSelf: alignIcon }, className: styles.contentPrefix },
                    React.createElement(SharedIcon, { rtlAutoFlip: true, svg: icon, size: "medium", color: "neutral_alt" }))) : null,
                React.createElement("div", { className: styles.content },
                    children,
                    errorMessage ? (React.createElement(ShellErrorMessage, { text: errorMessage, variant: errorVariant, verticalPosition: errorPosition, id: errorId })) : null),
                errorMessage ? (React.createElement("div", { className: styles.contentSuffix },
                    React.createElement(Icon, { color: "destructive", size: "medium", svg: WarningIcon }))) : null))));
});
export default ShellWrapperWithRef;
