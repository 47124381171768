import { AvroSchemaMapping, UNKNOWN_VALUE } from "../hooks/useEventTracking";
import { getMarketingTrackingVariable } from "./marketing-url-params";

export type EventTypes = "ACTION" | "PAGE_LOAD" | "API_CALL";

const LAST_SEEN_CONTEXT_EVENT_ID = "deprecated";

type FlightDetails = {
  search_list_item_order: number;
  segments: Segment[];
  price_total: number;
  ancillary_included: {
    ancillary: string;
    details: string;
  }[];
};
type Segment = {
  sequence: number;
  trip_time: number;
  stop_over_count: number;
  origin: string;
  destination: string;
  departure_time: string;
  arrvial_time: string;
  airline: string;
};

type StopFilter = {
  stop_count: number;
  min_price: number;
  selected: number;
};
type AirlineFilter = {
  airline_name: string;
  min_price: number;
  selected: 1 | 0;
};
type TimeOfDayFilter = {
  departure_arrival: string;
  segment_number: number;
  segment_time_min: number;
  segment_time_max: number;
};

const getFlightEventsContext = (
  contextName: keyof AvroSchemaMapping,
  requestId: string,
  eventType: EventTypes,
  eventName?: any,
  eventValue?: any,
  pointOfSale?: string
): AvroSchemaMapping["flight_events"]["context"] => ({
  device_type: UNKNOWN_VALUE,
  /*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
  language: UNKNOWN_VALUE,
  ip_country: UNKNOWN_VALUE,
  currency: UNKNOWN_VALUE,
  analytics_session_id: UNKNOWN_VALUE,
  request_id: requestId || UNKNOWN_VALUE,
  context_name: contextName,
  previous_context_id: getLastSeenContextEventId(),
  device_id: UNKNOWN_VALUE,
  client_type: UNKNOWN_VALUE,
  os: UNKNOWN_VALUE,
  app_version: UNKNOWN_VALUE,
  display_resolution: UNKNOWN_VALUE,
  network_type: UNKNOWN_VALUE,
  label: UNKNOWN_VALUE,
  adplat: getMarketingTrackingVariable("adplat") || UNKNOWN_VALUE,
  user_id: 0,
  affiliate_id: UNKNOWN_VALUE,
  soylent_email_id: UNKNOWN_VALUE,
  cookies: { BKNG_experiment_seed: UNKNOWN_VALUE },
  context_id: UNKNOWN_VALUE,
  is_internal_traffic: false,
  event_type: eventType,
  event_name: eventName || UNKNOWN_VALUE,
  event_value: eventValue || UNKNOWN_VALUE,
  is_bot: false,
  user_agent: UNKNOWN_VALUE,
  point_of_sale: pointOfSale || UNKNOWN_VALUE,
  session_id: UNKNOWN_VALUE,
  short_lived_session_id: UNKNOWN_VALUE,
  long_lived_session_id: UNKNOWN_VALUE
});

const getLastSeenContextEventId = () => {
  return LAST_SEEN_CONTEXT_EVENT_ID;
};

export { FlightDetails, StopFilter, AirlineFilter, TimeOfDayFilter, getFlightEventsContext, getLastSeenContextEventId };
