import { useEffect } from "react";
import { UIFlightData } from "@flights/types";
import flights_apex_web_move_baggage_features_to_server from "utils/experiments/apex/flights_apex_web_move_baggage_features_to_server";
import { areSellableFeaturesRequiredByAirline } from "utils/brandedFares";

export default function useTrackBaggageToServerStages(flights: UIFlightData[]) {
  useEffect(() => {
    if (flights.length === 0) return;
    if (flights.every((flight) => !flight.requestableBrandedFares)) return;

    flights_apex_web_move_baggage_features_to_server.stages.main();
    flights_apex_web_move_baggage_features_to_server.stages.sr_with_bf();

    if (flights.some((flight) => areSellableFeaturesRequiredByAirline(flight.segments))) {
      flights_apex_web_move_baggage_features_to_server.stages.sellable_required();
    }
  }, [flights]);
}
