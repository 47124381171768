import { parseDateISO8601, useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { useContext, useMemo, useState } from "react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import getFirstWeekDay from "../../utils/first-week-day.js";
export default function (props) {
    const { segmentIndex } = props;
    const { i18n, segments, searchType, trackV2, ipCountry, formatPrice } = useContext(SearchBoxContext);
    const { getWeekDaysHash, getMonthHash } = useFormatDateTime(i18n);
    const { departureDate, returnDate } = segments[segmentIndex];
    const { departureDate: prevDepartureDate } = segments[segmentIndex - 1] || {};
    const isRoundTrip = searchType === "ROUNDTRIP";
    const from = departureDate ? parseDateISO8601(departureDate) : undefined;
    const to = returnDate ? parseDateISO8601(returnDate) : undefined;
    const prevFrom = prevDepartureDate ? parseDateISO8601(prevDepartureDate) : undefined;
    const weekDaysHashShortest = getWeekDaysHash("shortest");
    const weekDaysHashFull = getWeekDaysHash("full");
    const monthHashFull = getMonthHash("full");
    const [dateValues, setDateValues] = useState({ from, to });
    const sortedDays = useMemo(() => {
        return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    }, []);
    const sortedMonth = useMemo(() => {
        return [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december"
        ];
    }, []);
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dayNames = useMemo(() => {
        return sortedDays.reduce((hash, day) => {
            hash[day] = weekDaysHashShortest[day];
            return hash;
        }, {});
    }, [sortedDays, weekDaysHashShortest]);
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const monthNames = useMemo(() => {
        return sortedMonth.reduce((hash, month) => {
            hash[month] = monthHashFull[month];
            return hash;
        }, {});
    }, [sortedMonth, monthHashFull]);
    const minDate = useMemo(() => {
        return prevFrom || new Date();
    }, [prevFrom]);
    const maxDate = useMemo(() => {
        return new Date(new Date().getTime() + 310176e5); // 360 days
    }, []);
    const baseDate = useMemo(() => {
        if (!isRoundTrip && from)
            return from;
        if (isRoundTrip && from && to)
            return from;
        return undefined;
    }, [from, to, isRoundTrip]);
    const firstWeekDay = getFirstWeekDay(ipCountry);
    const allowSameDateSelection = true;
    const singleDate = !isRoundTrip;
    return {
        allowSameDateSelection,
        baseDate,
        dateValues,
        dayNames,
        endDate: dateValues.to || null,
        firstWeekDay,
        i18n,
        isRoundTrip,
        maxDate,
        minDate,
        monthHashFull,
        monthNames,
        setDateValues,
        singleDate,
        sortedDays,
        sortedMonth,
        startDate: dateValues.from || null,
        trackV2,
        weekDaysHashFull,
        formatPrice
    };
}
