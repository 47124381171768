/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC } from "react";

import { Badge } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";
// import { t } from "@bookingcom/lingojs-core";
import { TranslationMessage } from "@bookingcom/lingojs-core/build/t";

import { UIFlightData, UIBrandedFareFeatureCategory } from "@flights/types";
import useUserAgent from "../../../../hooks/useUserAgent";
import styles from "./BaseFareAttributes.module.css";

type Props = {
  flight: UIFlightData;
};

const FEATURE_CATEGORY_TO_BADGE_COPY_MAP: { [key in UIBrandedFareFeatureCategory]?: TranslationMessage } = {
  // CANCEL: t("flights_brand_attribute_refund_label"),
  // CHANGE: t("flights_brand_attribute_change_label")
  // ------ jira : FUNNEL-2464
};

const BaseFareAttributes: FC<Props> = ({ flight }) => {
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const { brandedFareInfo } = flight;

  const badgesToRender = Object.keys(FEATURE_CATEGORY_TO_BADGE_COPY_MAP)
    .filter((categoryToRender) => brandedFareInfo?.features.some(({ category }) => category === categoryToRender))
    .map((categoryToRender) => {
      const copy = FEATURE_CATEGORY_TO_BADGE_COPY_MAP[categoryToRender];
      return <Badge text={i18n.trans(copy)} className={styles.attributeBadge} key={categoryToRender} />;
    });

  return badgesToRender.length > 0 ? (
    <div className={isMobile ? styles.rootMobile : undefined}>{badgesToRender}</div>
  ) : null;
};
export default BaseFareAttributes;
