import { useEffect, useMemo } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "..";
import { UIFlightData } from "@flights/types";
import useVirutalInterlining from "hooks/useVirutalInterlining";
import useIsInViewport from "hooks/useIsInViewport";
import useUserAgent from "hooks/useUserAgent";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: "flights_web_q3_blackout",
  stages: {
    main: 1, // Main stage - VI flight is in viewport,
    ddot: 2,
    mdot: 3
  },
  goals: {},
  goalsWithValue: []
});

const { stages } = utils;

export const useTrackVIBlackoutStages = (flights: UIFlightData[]) => {
  const { isVirtualInterliningFlight } = useVirutalInterlining();

  const viOfferIndex = useMemo(
    () => flights.findIndex((flight) => flight.segments.find((segment) => isVirtualInterliningFlight(segment))),
    [flights, isVirtualInterliningFlight]
  );

  const isOfferInViewport = useIsInViewport(
    viOfferIndex > -1 ? (document.querySelector(`#flight-card-${viOfferIndex}`) as HTMLElement) : null
  );

  const { isMobile } = useUserAgent();

  useEffect(() => {
    if (isOfferInViewport) {
      stages.main();
      isMobile ? stages.mdot() : stages.ddot();
    }
  }, [isOfferInViewport, isMobile]);
};

export default utils;
