import { createUseStore } from "@bookingcom/flights-core/store";
import * as searchReducer from "./reducer.js";
const getSBInitialState = () => ({
    searchbox: searchReducer.getInitialState()
});
const reducer = (state, action) => {
    return {
        searchbox: searchReducer.reducer(state.searchbox, action)
    };
};
// eslint-disable-next-line @typescript-eslint/naming-convention
const SBInitialState = getSBInitialState();
const createSearchboxStore = (state) => createUseStore(state, reducer);
const { StoreProvider, useStore, useActions } = createSearchboxStore(SBInitialState);
export { StoreProvider as SBStoreProvider, useStore as useSearchboxStore, useActions, getSBInitialState as getInitialState, createSearchboxStore as createStore };
