import React, { useContext } from "react";
import { CabinClassSelect } from "../CabinClass/index.js";
import { DirectFlightCheckbox } from "../DirectFlight/index.js";
import { OccupancyDropDown } from "../Occupancy/index.js";
import { SegmentHorizontal, AddSegment } from "../Segment/index.js";
import { Submit } from "../Submit/index.js";
import { SearchBoxContext, SearchboxController } from "../SearchBoxContext/index.js";
import { SearchTypeRadio } from "../SearchType/index.js";
import YellowBox from "./YellowBox.js";
import styles from "./SearchBoxHorizontalDefault.module.css";
const SearchBoxHorizontal = () => {
    const { searchType, isHeaderOnDarkBg = true, hideShadow, isNewUiExp } = useContext(SearchBoxContext);
    const isMultiStop = searchType === "MULTISTOP";
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.line },
            React.createElement("div", { className: `${styles.top} ${isHeaderOnDarkBg ? styles.topWhiteColor : ""}` },
                React.createElement(SearchTypeRadio, { isNewUiExp: isNewUiExp }),
                React.createElement(CabinClassSelect, null),
                isMultiStop ? React.createElement(OccupancyDropDown, null) : undefined,
                !isMultiStop ? React.createElement(DirectFlightCheckbox, null) : null)),
        React.createElement("div", { className: styles.line },
            React.createElement(YellowBox, { hideShadow: hideShadow },
                React.createElement("div", { className: styles.yellowInner },
                    React.createElement(SegmentHorizontal, null),
                    !isMultiStop ? React.createElement(Submit, null) : null))),
        isMultiStop ? (React.createElement("div", { className: styles.line },
            React.createElement("div", { className: styles.bottomMulti },
                React.createElement(AddSegment, { variant: "tertiary" }),
                React.createElement(Submit, null)))) : null));
};
export default function SearchBoxHorizontalDefault() {
    return (React.createElement(SearchboxController, null,
        React.createElement(SearchBoxHorizontal, null)));
}
