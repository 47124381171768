import React, { useRef } from "react";
import { UIAvailableExtraProducts, UIBrandedFareOrFlexTicket, UIFetchStatus, UIFlightData } from "@flights/types";
import { Button, Card, Popover, SkeletonLoader, Stack, Text, useTheme } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { lowerCase, startCase } from "lodash";
import { mcn } from "utils/mergeClassnames";
import { areSellableFeaturesRequiredByAirline } from "utils/brandedFares";
import useGoToFlightDetails from "components/elements/FareSelector/hooks/useGoToFlightDetails";
import Frame from "components/elements/Frame";
import FareFeatures from "components/elements/CheckoutFareInner/components/FareFeatures";
import FareFeaturesIcons from "./FareFeaturesIcons";
import FareCardPrice from "./FareCardPrice";
import styles from "./FareCard.desktop.module.css";
import flights_apex_web_expand_bf_on_sr from "utils/experiments/apex/flights_apex_web_expand_bf_on_sr";

type FareCardProps = {
  fareOffer: UIBrandedFareOrFlexTicket;
  baseOffer: UIFlightData;
  loadingStatus?: UIFetchStatus;
  index?: number;
  numberOfOffers?: number;
  ancillaries?: UIAvailableExtraProducts;
};

const FareCardDesktop = ({ baseOffer, fareOffer, loadingStatus, index, numberOfOffers }: FareCardProps) => {
  const i18n = useI18n();
  const theme = useTheme();
  const popoverRef = useRef(document.body);
  const goToFlightDetails = useGoToFlightDetails();

  const sellableFeaturesRequiredByAirline = areSellableFeaturesRequiredByAirline(baseOffer.segments);

  const { brandedFareInfo, includedProducts, isFlexTicket, ancillaries } = fareOffer;

  const inExpandFareOnSr = !!flights_apex_web_expand_bf_on_sr.trackWithDefaultStage();

  const renderedFeatures = (
    <FareFeatures
      brandedFareInfo={brandedFareInfo}
      includedProducts={includedProducts}
      ancillaries={ancillaries}
      sellableFeaturesRequiredByAirline={sellableFeaturesRequiredByAirline}
      showHeader={true}
      isPreCheckAndPay={true}
    />
  );

  return (
    <Popover triggerType="hover" containerRef={popoverRef}>
      <Popover.Trigger className={styles.popoverTrigger}>
        {(attributes) => (
          <Card className={styles.card}>
            <div>
              <Stack
                attributes={{
                  ...attributes,
                  tabIndex: 0
                }}
                justifyContent="space-between"
              >
                <Text
                  className={!inExpandFareOnSr && styles.title}
                  variant={inExpandFareOnSr ? "strong_2" : "strong_1"}
                  attributes={{ "data-fare-card-row": "title" }}
                >
                  {loadingStatus === "loading" ? (
                    <Stack gap={6} direction="row">
                      <Stack.Item grow>
                        <SkeletonLoader variant={inExpandFareOnSr ? "title" : undefined} />
                      </Stack.Item>
                      <SkeletonLoader width={theme.units.spacing_12x} />
                    </Stack>
                  ) : (
                    startCase(lowerCase(brandedFareInfo?.fareName))
                  )}
                </Text>
                <div>
                  <Frame pb={2} className={!inExpandFareOnSr && styles.fareFeaturesIcons} data-fare-card-row="icons">
                    <FareFeaturesIcons
                      includedProducts={includedProducts}
                      brandedFareInfo={brandedFareInfo}
                      limit={7}
                    />
                  </Frame>
                  <FareCardPrice
                    fareOffer={fareOffer}
                    baseOffer={baseOffer}
                    isFlexTicket={isFlexTicket}
                    inExpandFareOnSr={inExpandFareOnSr}
                  />
                  {renderedFeatures && (
                    <div id="features-aria" className={styles.ariaInvisible}>
                      {renderedFeatures}
                    </div>
                  )}
                </div>
              </Stack>
              <Button
                onClick={() => {
                  goToFlightDetails(fareOffer.token, baseOffer, isFlexTicket);
                }}
                className={mcn(styles.button, inExpandFareOnSr && styles.btnPadding)}
                variant="secondary"
                attributes={{
                  ...(index !== undefined && {
                    "aria-label": `${index + 1} of ${numberOfOffers}`
                  })
                }}
              >
                {i18n.trans(t("flights_apex_bf_sr_bf_card_cta"))}
              </Button>
            </div>
          </Card>
        )}
      </Popover.Trigger>
      <Popover.Content
        attributes={{
          style: {
            width: 240,
            whiteSpace: "pre-wrap",
            overflow: "hidden"
          }
        }}
      >
        {renderedFeatures}
      </Popover.Content>
    </Popover>
  );
};
export default FareCardDesktop;
