import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_expand_bf_on_sr",
  stages: {},
  goals: {},
  goalsWithValue: [],
  defaultStage: 1
});

export default utils;
