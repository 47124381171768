import { UIBrandedFareFeatureAvailability, UIBrandedFareFeatureName, UIFlightSegment } from "@flights/types";
import { BRANDED_FARE_FEATURES_CONFIG } from "constants/brandedFares";
import memoize from "lodash/memoize";
import { BrandedFareFeatureConfig } from "@flights/types";
import { hasCarrier } from "utils/carriers";
import { NON_INCLUDED_FEATURES_AIRLINES, SELLABLE_FEATURES_AIRLINES } from "constants/ancillaries";

export const getFeatureConfigsByName = memoize(() => {
  const featureConfigsByName: Partial<Record<UIBrandedFareFeatureName, BrandedFareFeatureConfig>> = {};

  for (const config of BRANDED_FARE_FEATURES_CONFIG) {
    featureConfigsByName[config.name] = config;
  }

  return featureConfigsByName;
});

export const getLabelCopy = (featureName: UIBrandedFareFeatureName, availability: UIBrandedFareFeatureAvailability) => {
  const config = getFeatureConfigsByName()[featureName];

  if (!config) return undefined;

  switch (availability) {
    case "SELLABLE":
      return config.sellableFeatureCopy;
    case "NOT_INCLUDED":
      return config.nonIncludedFeatureCopy;
    default:
      return config.includedFeatureCopy;
  }
};

export const areSellableFeaturesRequiredByAirline = (segments: UIFlightSegment[]) =>
  hasCarrier(segments, SELLABLE_FEATURES_AIRLINES);

export const areExcludedFeaturesRequiredByAirline = (segments: UIFlightSegment[]) =>
  hasCarrier(segments, NON_INCLUDED_FEATURES_AIRLINES);
