import React, { useCallback, useMemo, useRef, useState } from "react";
import { Text, Popover, ActionBar, Stack, Button, Box, SkeletonLoader } from "@bookingcom/bui-react";
import { CalendarIcon, CalendarDatesIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { ShellButton } from "../Shell/index.js";
import { Calendar, CalendarSelect } from "../Calendar/index.js";
import { t } from "@bookingcom/lingojs-core";
import useDate from "./useDate.js";
import styles from "./DateDropDown.module.css";
import { useFareCalendar } from "../../hooks/useFareCalendar/index.js";
import FareCalendarSelect from "../Calendar/FareCalendarSelect.js";
import useProfiler from "../../hooks/useFareCalendar/useProfiler.js";
import FareCalendarA11yAnnouncer from "../Calendar/FareCalendarA11yAnnouncer.js";
const errorId = "date_input_error";
const DateDropDown = (props) => {
    const { segmentIndex, position = "bottom-end", errorVariant = "tooltip" } = props;
    const { errorMessage, getTextNights, i18n, isDateSelected, isRoundTrip, isMultiStop, setFromTo, text, textPlaceholder, trackV2, isFareCalendarEnabled, isFareCalendarUIEnabled, from, to, onTrackSBExperiment } = useDate({ segmentIndex });
    const [showDropdown, setShowDropdown] = useState(false);
    const [fromKeayboard, setFromKeayboard] = useState(false);
    const buttonRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState({
        from: isFareCalendarEnabled ? from : undefined,
        to: isFareCalendarEnabled ? to : undefined
    });
    const { data, boundsData, isLoading, selectedPrice, selectedPriceText, currencyText, selectedDatesText, a11yAnnounceData, etTrackingHelper, getSelectedFareRange, onChangeSelectedDates, onOpenCalendar, onCloseCalendar, onCalendarNavigate, onCalendarKeyboardNavigate } = useFareCalendar(segmentIndex);
    const { isSolo, isCouple, isFamily } = useProfiler();
    const handleOnClose = useCallback(() => {
        setShowDropdown(false);
        setFromKeayboard(false);
        isFareCalendarEnabled && onCloseCalendar();
    }, [isFareCalendarEnabled, onCloseCalendar]);
    const handleOnSelectDateChange = useCallback(({ from, to }) => {
        setSelectedDate({ from, to });
    }, []);
    const handleOnDoneClick = useCallback(() => {
        const fareRange = getSelectedFareRange(selectedDate.from, selectedDate.to);
        setFromTo({ from: selectedDate.from, to: selectedDate.to, fareRange });
        trackV2("click_calendar_done");
        handleOnClose();
    }, [getSelectedFareRange, setFromTo, selectedDate.from, selectedDate.to, trackV2, handleOnClose]);
    const handleOnDateChange = useCallback((data) => {
        const { from, to } = data;
        isFareCalendarEnabled && onChangeSelectedDates({ departureDate: from, returnDate: to });
        const fareRange = getSelectedFareRange(from, to);
        setFromTo({ from, to, fareRange });
        if (isRoundTrip && from && to) {
            !isFareCalendarEnabled && handleOnClose();
        }
        else if (!isRoundTrip && from) {
            setFromTo({ from, fareRange });
            handleOnClose();
        }
    }, [isFareCalendarEnabled, onChangeSelectedDates, getSelectedFareRange, setFromTo, isRoundTrip, handleOnClose]);
    const handleOnClick = useCallback((e) => {
        e.stopPropagation();
        const isFromKeyboard = e.nativeEvent.isTrusted && e.nativeEvent.detail === 0 && !e.nativeEvent["pointerType"];
        setFromKeayboard(isFromKeyboard);
        trackV2("click_calendar");
        isFareCalendarEnabled && onOpenCalendar({ departureDate: from, returnDate: to });
        onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 1 });
        !isRoundTrip && !isMultiStop && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 2 });
        isRoundTrip && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 3 });
        isSolo() && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 4 });
        isCouple() && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 5 });
        isFamily() && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 6 });
        setTimeout(() => setShowDropdown(true), 0);
    }, [
        etTrackingHelper.etName,
        from,
        isCouple,
        isFamily,
        isFareCalendarEnabled,
        isMultiStop,
        isRoundTrip,
        isSolo,
        onOpenCalendar,
        onTrackSBExperiment,
        to,
        trackV2
    ]);
    const currencyContent = useMemo(() => {
        if (isFareCalendarUIEnabled && !currencyText && isLoading) {
            return React.createElement(SkeletonLoader, { variant: "title", width: "50%" });
        }
        if (currencyText) {
            return React.createElement(Text, null, currencyText);
        }
        return undefined;
    }, [currencyText, isFareCalendarUIEnabled, isLoading]);
    const footerContent = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return React.createElement(Text, { variant: "body_2" }, getTextNights(selectedDate));
        if (selectedPrice && selectedPriceText) {
            return (React.createElement(Stack, { gap: 0 },
                React.createElement(Text, { variant: "body_2", color: "neutral_alt" }, selectedPriceText),
                React.createElement(Text, { variant: "strong_1" }, selectedPrice)));
        }
        if (selectedDatesText) {
            return (React.createElement(Text, { variant: "body_2", color: "neutral_alt" }, selectedDatesText));
        }
        return undefined;
    }, [getTextNights, isFareCalendarUIEnabled, selectedDate, selectedDatesText, selectedPrice, selectedPriceText]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ShellButton, { icon: isDateSelected ? CalendarDatesIcon : CalendarIcon, errorMessage: showDropdown ? undefined : errorMessage, errorVariant: errorVariant, errorPosition: "bottom", placeholder: textPlaceholder, ref: buttonRef, "data-ui-name": `button_date_segment_${segmentIndex}`, onClick: handleOnClick, "data-ui-error": Boolean(errorMessage), errorId: errorId }, text ? (React.createElement(Text, { tagName: "span", variant: "body_2" }, text)) : (React.createElement(Text, { tagName: "span", color: "neutral_alt", variant: "body_2" }, textPlaceholder))),
        React.createElement(Popover, { fill: true, hideClose: true, forcePosition: true, size: "auto", triggerRef: buttonRef, active: showDropdown, onClose: handleOnClose, trapFocusMode: "hard", position: position, disableAnimation: true, keepMounted: false },
            React.createElement(Popover.Content, null,
                React.createElement("div", { className: isFareCalendarEnabled && !fromKeayboard ? styles.popovercontainerFareCalendar : styles.popovercontainer }, fromKeayboard ? (isFareCalendarEnabled ? (React.createElement(React.Fragment, null,
                    React.createElement(Box, { padding: 4 },
                        React.createElement(Stack, { gap: 4 },
                            currencyContent,
                            React.createElement(FareCalendarSelect, { variant: "horizontal", segmentIndex: segmentIndex, onDateChange: handleOnSelectDateChange, onFareCalendarDateChange: onChangeSelectedDates, fareCalendarData: boundsData, onCalendarNavigate: onCalendarKeyboardNavigate }))),
                    React.createElement(ActionBar, { button: {
                            wide: true,
                            onClick: handleOnDoneClick,
                            text: isFareCalendarUIEnabled
                                ? isRoundTrip
                                    ? i18n.trans(t("flights_mdot_fare_calendar_rt_cta"))
                                    : i18n.trans(t("flights_mdot_fare_calendar_one_way_cta"))
                                : i18n.trans(t("flights_action_done")),
                            size: isFareCalendarUIEnabled ? "large" : undefined,
                            attributes: {
                                "data-ui-name": "button_calendar_action_bar_done"
                            }
                        } }, footerContent))) : (React.createElement("div", { className: styles.calendarWrapper },
                    React.createElement("div", { className: styles.calendarWrapperInner },
                        React.createElement(CalendarSelect, { variant: "horizontal", segmentIndex: segmentIndex, onDateChange: handleOnSelectDateChange })),
                    React.createElement(ActionBar, { button: {
                            wide: true,
                            onClick: handleOnDoneClick,
                            text: i18n.trans(t("flights_action_done")),
                            attributes: {
                                "data-ui-name": "button_calendar_action_bar_done"
                            }
                        } },
                        React.createElement(Text, { variant: "body_2" }, getTextNights(selectedDate)))))) : (React.createElement("div", { className: styles.calendarWrapperInner }, isFareCalendarEnabled ? (React.createElement(Stack, { gap: 4 },
                    currencyContent,
                    React.createElement(Calendar, { fullHeight: true, mode: "double", onDateChange: handleOnDateChange, segmentIndex: segmentIndex, fareCalendarData: isFareCalendarEnabled ? data : undefined, fareCalendarLoading: isFareCalendarEnabled ? isLoading : undefined, onCalendarNavigate: isFareCalendarEnabled ? onCalendarNavigate : undefined }),
                    isFareCalendarUIEnabled && isRoundTrip && (React.createElement(Stack, { direction: "row", alignItems: "center" },
                        React.createElement(Stack.Item, { grow: true }, selectedPrice && selectedPriceText ? (React.createElement(Stack, { gap: 0 },
                            React.createElement(Text, { variant: "body_2", color: "neutral_alt" }, selectedPriceText),
                            React.createElement(Text, { variant: "strong_1" }, selectedPrice))) : selectedDatesText ? (React.createElement(Text, { variant: "body_2", color: "neutral_alt" }, selectedDatesText)) : undefined),
                        React.createElement(Button, { size: "large", onClick: handleOnClose }, i18n.trans(t("flights_fare_calendar_cta_done"))))))) : (React.createElement(Calendar, { fullHeight: true, mode: "double", onDateChange: handleOnDateChange, segmentIndex: segmentIndex }))))),
                a11yAnnounceData && (React.createElement(FareCalendarA11yAnnouncer, { status: a11yAnnounceData.status, lowestPricesData: a11yAnnounceData.lowestPricesData }))))));
};
export default DateDropDown;
