import React, { useMemo, useState } from "react";
import { InputRadioGroup, InputRadio, Stack } from "@bookingcom/bui-react";
import useSearchType from "./useSearchType.js";
import styles from "./SearchTypeRadio.module.css";
import { isKeyboardFocusEvent } from "../../utils/isKeyboardFocusEvent.js";
const SearchTypeRadio = ({ isNewUiExp }) => {
    const [isFocused, setIsFocused] = useState("");
    const { searchType, searchTypeOptions, onSearchTypeChange } = useSearchType();
    const options = useMemo(() => searchTypeOptions.map((o) => ({
        ...o,
        attributes: { "data-ui-name": `search_type_${o.value.toLowerCase()}` },
        inputAttributes: {
            "data-ui-name": `input_search_type_${o.value.toLowerCase()}`
        }
    })), [searchTypeOptions]);
    return (React.createElement(InputRadioGroup, { name: "sr_search_type", value: searchType, onChange: ({ value }) => {
            onSearchTypeChange(value);
        }, className: styles.wrapper, attributes: { "data-ui-name": "search_type" } },
        React.createElement(Stack, { direction: "row", gap: 4 }, options.map((option) => (React.createElement(InputRadio, { ...option, key: option.value, className: `${isNewUiExp ? styles.darkRadio : ""} ${option.value === isFocused && isNewUiExp ? styles.focusedRadio : ""}`, onFocus: (event) => {
                isKeyboardFocusEvent(event) && setIsFocused(option.value);
            }, onBlur: () => setIsFocused("") }))))));
};
export default SearchTypeRadio;
