/**
 * TEMP TRANSFORMATION
 * ADAPTER FROM OLD TO NEW SEARCH CONTEXT
 * SHOULD BE CLEANED UP WHE FULLY MIGRATED
 * AND CONVERTED TO NORMAL STATE
 */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SBContext } from "../../component-controllers/SearchboxController.js";
import { useSearchboxStore } from "../../store/searchBoxStore.js";
import useValidation from "./useValidation.js";
export default function useSearchBox() {
    const oldContext = useContext(SBContext);
    const { actions, onTrackSBExperimentCustomGoal } = oldContext;
    const oldStore = useSearchboxStore();
    const { validate, focusFirstInvalidInput } = useValidation();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isValid, setIsValid] = useState(false);
    // cabin class ==========
    const cabinClass = useMemo(() => oldContext.cabinClass, [oldContext.cabinClass]);
    const setCabinClass = useCallback((cabinClass) => {
        actions.setCabinClass(cabinClass);
    }, [actions]);
    // passengers ===========
    const occupancy = useMemo(() => ({
        adults: oldContext.adults,
        children: oldContext.children
    }), [oldContext.adults, oldContext.children]);
    const setOccupancy = useCallback((value) => {
        onTrackSBExperimentCustomGoal({
            name: "flights_web_a11y_sb_v2_ddot",
            customGoal: 2
        });
        actions.setOccupancy(value);
    }, [actions, onTrackSBExperimentCustomGoal]);
    // segments ================
    const segments = useMemo(() => oldContext.searchSegments, [oldContext.searchSegments]);
    const setSegmentTo = useCallback((locations, idx) => {
        actions.setTo(locations, idx);
    }, [actions]);
    const setSegmentFrom = useCallback((locations, idx) => {
        if (locations?.length > 1) {
            onTrackSBExperimentCustomGoal({
                name: "flights_web_a11y_sb_v2_ddot",
                customGoal: 1
            });
        }
        actions.setFrom(locations, idx);
    }, [actions, onTrackSBExperimentCustomGoal]);
    const removeSegment = useCallback((idx) => {
        actions.removeSegment(idx);
    }, [actions]);
    const swapSegment = useCallback((idx) => {
        actions.swapFromTo(idx);
        onTrackSBExperimentCustomGoal({
            name: "flights_web_a11y_sb_v2_ddot",
            customGoal: 4
        });
    }, [actions, onTrackSBExperimentCustomGoal]);
    const addSegment = useCallback(() => {
        actions.addSegment();
    }, [actions]);
    const clearSegments = useCallback(() => {
        actions.clearSegments();
    }, [actions]);
    // direct flight ================
    const setIsDirect = useCallback((isDirect) => {
        actions.setIsDirect(isDirect);
    }, [actions]);
    const isDirect = useMemo(() => {
        return oldStore.searchbox.isDirect;
    }, [oldStore.searchbox.isDirect]);
    // Date ================
    const setDate = useCallback((range, segmentIndex, fareRange) => {
        actions.setDateRange(range, segmentIndex, fareRange);
    }, [actions]);
    // search type ==========
    const searchType = useMemo(() => oldContext.type, [oldContext.type]);
    // update segments in store based on the search type
    // make sure no multiple locations for MULTISTOP
    const cleanupBasedOnSearchType = useCallback((searchType) => {
        if (searchType === "MULTISTOP") {
            const segment0 = segments?.[0];
            if (segment0?.from?.length > 1 || segment0?.to?.length > 1) {
                const from = segment0?.from?.[0];
                const to = segment0?.to?.[0];
                setSegmentFrom(from ? [from] : [], 0);
                setSegmentTo(to ? [to] : [], 0);
            }
            if (segments.length === 1 && !oldContext?.config?.hideMultiCityEmptyInputs) {
                addSegment();
            }
        }
        else if (!oldStore.searchbox.initial) {
            clearSegments();
        }
    }, [
        addSegment,
        clearSegments,
        oldContext?.config?.hideMultiCityEmptyInputs,
        oldStore.searchbox.initial,
        segments,
        setSegmentFrom,
        setSegmentTo
    ]);
    const setSearchType = useCallback((searchType) => {
        setIsSubmitted(false);
        actions.setType(searchType);
        if (window.location.pathname.startsWith("/fly-anywhere")) {
            if (searchType === "MULTISTOP") {
                actions.setTo([], 0);
            }
        }
        cleanupBasedOnSearchType(searchType);
    }, [actions, cleanupBasedOnSearchType]);
    // Search ==============
    const doSearch = useCallback(() => {
        setIsSubmitted(true);
        if (validate(segments, occupancy, searchType)) {
            setIsValid(true);
            const etName = oldContext.isMobile ? "flights_web_fare_calendar_mdot_v2" : "flights_web_fare_calendar_desktop_v2";
            const type = searchType === "ONEWAY" ? "oneWay" : searchType === "ROUNDTRIP" ? "roundTrip" : undefined;
            const fareRange = type && oldContext.searchSegments[0].fareRange?.[type];
            fareRange && oldContext.onTrackSBExperimentCustomGoal({ name: etName, customGoal: fareRange == "LOW" ? 2 : 1 });
            oldContext.doSearch();
        }
        else {
            setIsValid(false);
            oldContext.onTrackSBExperimentCustomGoal({
                name: "flights_web_a11y_sb_v2_ddot",
                customGoal: 3
            });
            focusFirstInvalidInput();
        }
    }, [oldContext, validate, focusFirstInvalidInput, segments, occupancy, searchType]);
    useEffect(() => {
        setIsValid(validate(segments, occupancy, searchType));
    }, [searchType, segments, occupancy]);
    return {
        i18n: oldContext.i18n,
        autoCompleteBaseURL: oldContext.searchAutoCompleteBaseURL,
        lang: oldContext.lang,
        trackV2: oldContext.trackV2,
        dateFNSLocale: oldContext.dateFNSLocale,
        doSearch,
        isSubmitted,
        ipCountry: oldContext.ipCountry,
        // ---------------
        searchType,
        setSearchType,
        cabinClass,
        setCabinClass,
        occupancy,
        setOccupancy,
        segments,
        setSegmentTo,
        setSegmentFrom,
        swapSegment,
        removeSegment,
        addSegment,
        setIsDirect,
        isDirect,
        setDate,
        isValid,
        trackLandingGoal: oldContext.trackLandingGoal,
        onTrackSBExperimentCustomGoal: oldContext.onTrackSBExperimentCustomGoal,
        onTrackSBExperimentStage: oldContext.onTrackSBExperimentStage,
        isHeaderOnDarkBg: !!oldContext.isHeaderOnDarkBg,
        isHeaderFramed: !!oldContext.isHeaderFramed,
        hideShadow: !!oldContext.hideShadow,
        isMobile: !!oldContext.isMobile,
        isMeta: !!oldContext.isMeta,
        onGetSBExperimentVariant: oldContext.onGetSBExperimentVariant,
        onTrackSBExperiment: oldContext.onTrackSBExperiment,
        onTrackSBMetaExperimentStage: oldContext.onTrackSBMetaExperimentStage,
        disableAutocompleteMultiSelect: !!oldContext.hideLocationMultiSelect,
        isNewUiExp: !!oldContext.isNewUiExp,
        showNonStopExp: !!oldContext.showNonStopExp,
        fareCalendarURL: oldContext.fareCalendarURL,
        isFareCalendarEnabled: !!oldContext.isFareCalendarEnabled,
        isFareCalendarUIEnabled: !!oldContext.isFareCalendarUIEnabled,
        formatPrice: oldContext.priceFormatter,
        multiSelectVariant: oldContext.multiSelectVariant,
        multiSelectName: oldContext.multiSelectName,
        infantMaxAge: oldContext.infantMaxAge,
        fixInputSpacing: oldContext.fixInputSpacing,
        isFlyAnywhereExp: !!oldContext.isFlyAnywhereExp,
        trackFlyAnywhereWww: oldContext.trackFlyAnywhereWww,
        trackFlyAnywhereMdot: oldContext.trackFlyAnywhereMdot,
        // START - flights_web_ios_mdotvalue_searchbox_segment_focus
        onTrackIosSegmentFocusStages: oldContext.onTrackIosSegmentFocusStages,
        config: oldContext.config
        // END - flights_web_ios_mdotvalue_searchbox_segment_focus
    };
}
