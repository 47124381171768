import React, { useCallback, useState, useEffect, useMemo } from "react";
import { t } from "@bookingcom/lingojs-core";
import { useSearchboxStore, useActions, SBStoreProvider } from "../store/searchBoxStore.js";
import { actions as searchActions } from "../store/actions.js";
import { ANYWHERE_LOCATION } from "../utils/constants.js";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SBContext = React.createContext(undefined);
const PARAM_LIST_SEPARATOR = ",";
const URL_IATA_LIST_SEPARATOR = "_";
const MULTISTOP_IATA_LIST_SEPARATOR = "|";
const LOCATION_IATA_CODE_AND_TYPE_SEPARATOR = ".";
const useSearchbox = (props) => {
    const { track, trackV2, onSearch, onDirectFlightsChange, i18n, dateFNSLocale, directFlightsFilterApplied, isMultiStopEnabled = true, fareCalendarURL, fareCalendarConfig, priceFormatter, onGetSBExperimentVariant, onTrackSBExperiment, onTrackSBExperimentCustomGoal, onTrackSBExperimentStage, onTrackSBMetaExperimentStage, 
    // START - flights_web_ios_mdotvalue_searchbox_segment_focus
    onTrackIosSegmentFocusStages, 
    // END - flights_web_ios_mdotvalue_searchbox_segment_focus
    isRTL, isA11yEnabled, travelPurpose, onTravelPurposeChange, hideLocationMultiSelect, isShowTravelPurposeCheckbox, trackLandingGoal, isHeaderOnDarkBg, isHeaderFramed, hideShadow, isMobile, isMeta, ipCountry, config, isNewUiExp, showNonStopExp, multiSelectVariant, multiSelectName, infantMaxAge, fixInputSpacing, isFlyAnywhereExp, trackFlyAnywhereWww, trackFlyAnywhereMdot } = props;
    const store = useSearchboxStore();
    const { searchSegments, type, adults, children, cabinClass, isDirect, fareCalendar } = store.searchbox;
    const actions = useActions(searchActions);
    const { initialValue, searchAutoCompleteBaseURL, defaultSegmentLength } = props;
    // ? newValue is used to handle fly-anywhere case and pass the init value in searchBox
    const newValue = {
        ...initialValue,
        searchSegments: [
            {
                ...initialValue.searchSegments[0],
                from: [
                    {
                        ...initialValue.searchSegments[0].from[0]
                    }
                ],
                to: [
                    !isFlyAnywhereExp
                        ? {
                            ...initialValue.searchSegments[0].to[0]
                        }
                        : { ...ANYWHERE_LOCATION, code: i18n.trans(t("flights_search_destination_anywhere")) }
                ]
            },
            ...initialValue.searchSegments.slice(1)
        ]
    };
    useEffect(() => {
        initialValue &&
            actions.setInitial(isFlyAnywhereExp && window.location.pathname.startsWith("/fly-anywhere") ? newValue : initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        actions.setIsDirect(directFlightsFilterApplied);
    }, [actions, directFlightsFilterApplied]);
    const lang = i18n.language ? i18n.language.split("-")[0] : "en";
    const SEARCH_TYPE_MAP = {
        ROUNDTRIP: i18n.trans(t("flights_route_round_trip")),
        ONEWAY: i18n.trans(t("flights_route_one_way"))
    };
    if (isMultiStopEnabled)
        SEARCH_TYPE_MAP["MULTISTOP"] = i18n.trans(t("flights_multicity_label"));
    const [errorState, setErrorState] = useState({
        isSubmitted: false,
        hasError: false,
        error: undefined
    });
    const getLocationsQueryParamValue = useCallback((locations) => {
        return locations
            .map(({ code, type }) => [code, type].filter(Boolean).join(LOCATION_IATA_CODE_AND_TYPE_SEPARATOR))
            .join(URL_IATA_LIST_SEPARATOR);
    }, []);
    const getMultiStopAirportsForSegment = useCallback((searchSegments, airportType) => {
        return searchSegments
            .map((eachSegment) => {
            const { from, to } = eachSegment;
            if (airportType === "from") {
                const fromAirport = from && getLocationsQueryParamValue(from);
                return fromAirport;
            }
            else {
                const toAirport = to && getLocationsQueryParamValue(to);
                return toAirport;
            }
        })
            .join(MULTISTOP_IATA_LIST_SEPARATOR);
    }, [getLocationsQueryParamValue]);
    const handleErrorForSearchBox = useCallback((search) => {
        const { type, searchSegments, children } = search;
        /**
         * Check if all children ages are selected
         * On adding a child they are intialsed in the store with default age value as -1, check InputOccupancy component.
         */
        const anyChildAgeMissing = children.some((childAge) => childAge === -1);
        if (anyChildAgeMissing) {
            setErrorState({
                isSubmitted: false,
                hasError: true,
                error: i18n.trans(t("flights_child_age_missing_error"))
            });
            return;
        }
        switch (type) {
            case "ONEWAY":
            case "ROUNDTRIP": {
                //There will be only one segment for oneway/roundtrips
                const { from, to, departureDate, returnDate } = searchSegments[0];
                if (!from ||
                    !to ||
                    !from.length ||
                    !to.length ||
                    !departureDate ||
                    (search.type == "ROUNDTRIP" && !returnDate)) {
                    setErrorState({
                        isSubmitted: false,
                        hasError: true,
                        error: i18n.trans(t("flights_search_error_date_airport"))
                    });
                    return;
                }
                const fromAirport = getLocationsQueryParamValue(from);
                const toAirport = getLocationsQueryParamValue(to);
                const fromCodes = fromAirport.split(URL_IATA_LIST_SEPARATOR);
                const toCodes = toAirport.split(URL_IATA_LIST_SEPARATOR);
                const sameAirports = fromCodes.filter((fromCode) => {
                    return toCodes.includes(fromCode);
                });
                if (sameAirports.length > 0) {
                    setErrorState({
                        isSubmitted: false,
                        hasError: true,
                        error: i18n.trans(t("flights_search_error_airport_duplicate"))
                    });
                    return;
                }
                setErrorState({
                    isSubmitted: true,
                    hasError: false,
                    error: undefined
                });
                return { fromAirport, toAirport };
            }
            case "MULTISTOP": {
                /**Handle SB error for multistopselection */
                let hasMultiStopError = false;
                let hasSameAirportError = false;
                searchSegments.map((eachSegment) => {
                    const { from, to, departureDate } = eachSegment;
                    if (!from || !from.length || !to || !to.length || !departureDate) {
                        hasMultiStopError = true;
                    }
                    const fromAirport = getLocationsQueryParamValue(from);
                    const toAirport = getLocationsQueryParamValue(to);
                    if (fromAirport === toAirport) {
                        hasSameAirportError = true;
                    }
                });
                /**Handle error for date selection */
                const multiStopDates = searchSegments.reduce((acc, eachSegment) => {
                    eachSegment.departureDate && acc.push(eachSegment.departureDate);
                    return acc;
                }, []);
                const hasMultiStopDatesError = multiStopDates.some((eachDate, index) => {
                    const nextDate = multiStopDates[index + 1];
                    const currentTimeInMilliseconds = new Date(eachDate).getTime();
                    const nextTimeInMilliseconds = new Date(nextDate).getTime();
                    return index < multiStopDates.length - 1 && currentTimeInMilliseconds > nextTimeInMilliseconds;
                });
                if (hasMultiStopError || hasMultiStopDatesError) {
                    setErrorState({
                        isSubmitted: false,
                        hasError: true,
                        error: i18n.trans(t("flights_search_error_date_airport"))
                    });
                    return;
                }
                if (hasSameAirportError) {
                    setErrorState({
                        isSubmitted: false,
                        hasError: true,
                        error: i18n.trans(t("flights_search_error_airport_duplicate"))
                    });
                    return;
                }
                const fromAirport = getMultiStopAirportsForSegment(searchSegments, "from");
                const toAirport = getMultiStopAirportsForSegment(searchSegments, "to");
                return { fromAirport, toAirport };
            }
        }
    }, [i18n, setErrorState, getMultiStopAirportsForSegment, getLocationsQueryParamValue]);
    const doSearch = useCallback(() => {
        const search = { ...store.searchbox };
        search.searchSegments = search.searchSegments.filter((segment, idx) => {
            if (idx === 0)
                return true;
            const { from, to, departureDate, returnDate } = segment;
            return !!from.length || !!to.length || !!departureDate || !!returnDate;
        });
        const airports = handleErrorForSearchBox(search);
        if (airports) {
            const { fromAirport, toAirport } = airports;
            const params = new URLSearchParams();
            params.set("type", search.type.toString());
            params.set("adults", `${search.adults}`);
            params.set("cabinClass", search.cabinClass);
            params.set("children", search.children.join(PARAM_LIST_SEPARATOR));
            /** Set from and to Airport Params */
            params.set("from", fromAirport);
            params.set("to", toAirport);
            /** Set from and to Country Params */
            const fromCountryParams = [];
            const toCountryParams = [];
            search.searchSegments.forEach((segment) => {
                const { from, to } = segment;
                from && fromCountryParams.push(from.map((location) => location.country).join(PARAM_LIST_SEPARATOR));
                to && toCountryParams.push(to.map((location) => location.country).join(PARAM_LIST_SEPARATOR));
            });
            params.set("fromCountry", fromCountryParams.join(MULTISTOP_IATA_LIST_SEPARATOR));
            params.set("toCountry", toCountryParams.join(MULTISTOP_IATA_LIST_SEPARATOR));
            /** Set from and to LocationName Params */
            const fromLocationNameParams = [];
            const toLocationParams = [];
            search.searchSegments.forEach((segment) => {
                const { from, to } = segment;
                from &&
                    fromLocationNameParams.push(from.map((location) => location.name).join(PARAM_LIST_SEPARATOR));
                to && toLocationParams.push(to.map((location) => location.name).join(PARAM_LIST_SEPARATOR));
            });
            params.set("fromLocationName", fromLocationNameParams.join(MULTISTOP_IATA_LIST_SEPARATOR));
            params.set("toLocationName", toLocationParams.join(MULTISTOP_IATA_LIST_SEPARATOR));
            if (isDirect) {
                params.set("stops", "0");
            }
            if (type === "MULTISTOP") {
                const multiStopDates = search.searchSegments
                    .map((segment) => {
                    const { departureDate } = segment;
                    return departureDate;
                })
                    .join(MULTISTOP_IATA_LIST_SEPARATOR);
                params.set("multiStopDates", multiStopDates);
            }
            else {
                const { departureDate, returnDate } = search.searchSegments[0];
                departureDate && params.set("depart", departureDate);
                if (search.type == "ROUNDTRIP") {
                    params.set("return", `${returnDate}`);
                }
            }
            onSearch({
                fromAirport,
                toAirport,
                params,
                isDirect,
                searchState: search,
                fareCalendar
            });
        }
    }, [handleErrorForSearchBox, store.searchbox, type, onSearch, isDirect, fareCalendar]);
    const switchOptions = Object.keys(SEARCH_TYPE_MAP).map((value) => ({
        label: SEARCH_TYPE_MAP[value],
        value: value,
        id: `searchbox_controller_trip_type_${value}`,
        inputAttributes: {
            "data-testid": `searchbox_controller_trip_type_${value}`
        }
    }));
    const setDirectFlights = () => {
        const params = new URLSearchParams();
        const stops = isDirect ? 0 : undefined;
        if (stops !== undefined) {
            params.set("stops", stops.toString());
        }
        actions.setIsDirect(!isDirect);
        onDirectFlightsChange({ isDirect: !isDirect, params });
    };
    // Checks for Fare Calendar
    const segment = searchSegments[0];
    const fareCalendarMinRequirements = segment.from.length === 1 && segment.to.length === 1 && cabinClass === "ECONOMY" && !isMeta;
    const fareCalendarEligibility = useMemo(() => {
        if (fareCalendarMinRequirements &&
            isMobile &&
            fareCalendarConfig.mdot &&
            fareCalendarConfig.mdot.enabled &&
            fareCalendarConfig.mdot.searchTypes.includes(type)) {
            return {
                enabled: true,
                enabledUI: fareCalendarConfig.mdot.enabledUI
            };
        }
        if (fareCalendarMinRequirements &&
            !isMobile &&
            fareCalendarConfig.desktop &&
            fareCalendarConfig.desktop.enabled &&
            fareCalendarConfig.desktop.searchTypes.includes(type)) {
            return {
                enabled: true,
                enabledUI: fareCalendarConfig.desktop.enabledUI
            };
        }
        return { enabled: false, enabledUI: false };
    }, [fareCalendarConfig.desktop, fareCalendarConfig.mdot, fareCalendarMinRequirements, isMobile, type]);
    return {
        actions,
        searchSegments,
        type,
        adults,
        children,
        cabinClass,
        errorState,
        dateFNSLocale,
        lang,
        i18n,
        doSearch,
        track,
        trackV2,
        switchOptions,
        setDirectFlights,
        isMultiStopEnabled,
        fareCalendarURL,
        isFareCalendarEnabled: fareCalendarEligibility.enabled,
        isFareCalendarUIEnabled: fareCalendarEligibility.enabledUI,
        priceFormatter,
        setErrorState,
        searchAutoCompleteBaseURL,
        defaultSegmentLength,
        onGetSBExperimentVariant,
        onTrackSBExperiment,
        onTrackSBExperimentCustomGoal,
        onTrackSBExperimentStage,
        onTrackSBMetaExperimentStage,
        // START - flights_web_ios_mdotvalue_searchbox_segment_focus
        onTrackIosSegmentFocusStages,
        // END - flights_web_ios_mdotvalue_searchbox_segment_focus
        isRTL,
        isA11yEnabled,
        travelPurpose,
        onTravelPurposeChange,
        hideLocationMultiSelect,
        isShowTravelPurposeCheckbox,
        trackLandingGoal,
        isHeaderOnDarkBg,
        isHeaderFramed,
        hideShadow,
        isMobile,
        isMeta,
        ipCountry,
        config,
        isNewUiExp,
        showNonStopExp,
        multiSelectVariant,
        multiSelectName,
        infantMaxAge,
        fixInputSpacing,
        isFlyAnywhereExp,
        trackFlyAnywhereWww,
        trackFlyAnywhereMdot
    };
};
// eslint-disable-next-line @typescript-eslint/naming-convention
const SBContextController = (props) => {
    const searchBoxData = useSearchbox(props);
    return React.createElement(SBContext.Provider, { value: searchBoxData }, props.children(searchBoxData));
};
const SearchboxController = (props) => {
    return (React.createElement(SBStoreProvider, null,
        React.createElement(SBContextController, { ...props })));
};
export { SBContext };
export default SearchboxController;
