import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { useContext } from "react";
import { OCCUPANCY_MAX_ALLOWED_PASSENGERS, OCCUPANCY_INFANT_AGE } from "../../utils/constants.js";
import { t } from "@bookingcom/lingojs-core";
export default function () {
    const { occupancy, setOccupancy, i18n, trackV2, isSubmitted, lang, isNewUiExp, searchType } = useContext(SearchBoxContext);
    const isOccupancySelected = !!occupancy.adults && !occupancy.children.some((c) => c === undefined || c === null);
    const infantsCount = occupancy.children.filter((a) => a < OCCUPANCY_INFANT_AGE).length;
    const isValidInfants = infantsCount <= occupancy.adults;
    const errorMessage = isSubmitted && (!isOccupancySelected || !isValidInfants)
        ? i18n.trans(t("flights_search_error_travellers"))
        : undefined;
    const occupancyText = occupancy.children.length === 0
        ? i18n.trans(t("flights_search_passenger_count_adults", {
            num_exception: occupancy.adults,
            variables: { num_travellers: occupancy.adults }
        }))
        : i18n.trans(t("flights_search_passenger_count_mix", {
            num_exception: occupancy.adults + occupancy.children.length,
            variables: {
                num_travellers: occupancy.adults + occupancy.children.length
            }
        }));
    const count = (occupancy?.adults || 1) + (occupancy?.children?.filter((_) => _ !== undefined)?.length || 0);
    return {
        errorMessage,
        i18n,
        isOccupancySelected,
        isSubmitted,
        isValidInfants,
        lang,
        maxCount: OCCUPANCY_MAX_ALLOWED_PASSENGERS,
        occupancy,
        count,
        setOccupancy,
        trackV2,
        occupancyText,
        isNewUiExp,
        searchType
    };
}
