import React, { forwardRef, useMemo } from "react";
import { Icon } from "@bookingcom/bui-react";
import { WarningIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import SharedIcon from "./Icon.js";
import ShellErrorMessage from "./ErrorMessage.js";
import styles from "./ShellButton.module.css";
const ShellButtonWithRef = forwardRef(function ShellButton(props, ref) {
    const { children, className, errorId, errorMessage, errorPosition, errorVariant, icon, size, alignIcon, ...rest } = props;
    const rootStyles = useMemo(() => {
        return [className, styles.btn, errorMessage && styles.btnError, size === "large" && styles.btnLarge]
            .filter(Boolean)
            .join(" ");
    }, [className, errorMessage, size]);
    return (React.createElement("button", { ...rest, ref: ref, type: "button", className: rootStyles, "aria-labelledby": errorMessage ? errorId : undefined },
        React.createElement("div", { className: styles.inner },
            icon ? (React.createElement("span", { className: styles.prefix, style: { alignSelf: alignIcon } },
                React.createElement(SharedIcon, { rtlAutoFlip: true, svg: icon, size: "medium", color: "neutral_alt" }))) : null,
            React.createElement("span", { className: styles.content },
                React.createElement("span", { className: styles.contentInner }, children),
                errorMessage ? (React.createElement(ShellErrorMessage, { text: errorMessage, variant: errorVariant, verticalPosition: errorPosition, id: errorId })) : null),
            errorMessage ? (React.createElement("span", { className: styles.suffix },
                React.createElement(Icon, { color: "destructive", size: "medium", svg: WarningIcon }))) : null),
        React.createElement("div", { style: { display: "none" } },
            React.createElement("div", { className: styles.wrapper },
                icon ? (React.createElement("div", { className: styles.contentPrefix },
                    React.createElement(SharedIcon, { rtlAutoFlip: true, svg: icon, size: "medium", color: "neutral_alt" }))) : null,
                React.createElement("div", { className: styles.content },
                    React.createElement("span", { className: styles.contentInner }, children),
                    errorMessage ? (React.createElement(ShellErrorMessage, { text: errorMessage, variant: errorVariant, verticalPosition: errorPosition, id: errorId })) : null),
                errorMessage ? (React.createElement("div", { className: styles.contentSuffix },
                    React.createElement(Icon, { color: "destructive", size: "medium", svg: WarningIcon }))) : null))));
});
export default ShellButtonWithRef;
