import { createAction } from "@bookingcom/flights-core/store";
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["setType"] = "search/setType";
    ActionTypes["setFrom"] = "search/setFrom";
    ActionTypes["setTo"] = "search/setTo";
    ActionTypes["setDateRange"] = "search/setDateRange";
    ActionTypes["setOccupancyAndCabinClass"] = "search/setOccupancyAndCabinClass";
    ActionTypes["setOccupancy"] = "search/setOccupancy";
    ActionTypes["setCabinClass"] = "search/setCabinClass";
    ActionTypes["setIsDirect"] = "search/setIsDirect";
    ActionTypes["setSortOrder"] = "search/setSortOrder";
    ActionTypes["swapFromTo"] = "search/swapFromTo";
    ActionTypes["setInitial"] = "search/setInitial";
    ActionTypes["removeSegment"] = "search/removeSegment";
    ActionTypes["addSegment"] = "search/addSegment";
    ActionTypes["clearSegments"] = "search/clearSegments";
    ActionTypes["resetSegment"] = "search/resetSegment";
    ActionTypes["fetchFareCalendar"] = "fareCalendar/fetch";
    ActionTypes["fetchFareCalendarSuccess"] = "fareCalendar/fetchSuccess";
    ActionTypes["fetchFareCalendarError"] = "fareCalendar/fetchError";
})(ActionTypes || (ActionTypes = {}));
export const actions = {
    setType: createAction((type) => ({
        type: ActionTypes.setType,
        payload: { type }
    })),
    removeSegment: createAction((searchSegmentIndex) => ({
        type: ActionTypes.removeSegment,
        payload: { searchSegmentIndex }
    })),
    addSegment: createAction(() => ({
        type: ActionTypes.addSegment,
        payload: {}
    })),
    clearSegments: createAction(() => ({
        type: ActionTypes.clearSegments,
        payload: {}
    })),
    setFrom: createAction((from, searchSegmentIndex) => ({
        type: ActionTypes.setFrom,
        payload: { from, searchSegmentIndex }
    })),
    setTo: createAction((to, searchSegmentIndex) => ({
        type: ActionTypes.setTo,
        payload: { to, searchSegmentIndex }
    })),
    /* This "fareRange" is purely to help tracking, remove if fullon */
    setDateRange: createAction((range, searchSegmentIndex, fareRange) => ({
        type: ActionTypes.setDateRange,
        payload: { range, searchSegmentIndex, fareRange }
    })),
    /* ------------------------------------------------- */
    setOccupancyAndCabinClass: createAction((data) => ({
        type: ActionTypes.setOccupancyAndCabinClass,
        payload: { ...data }
    })),
    setOccupancy: createAction((occupancy) => ({
        type: ActionTypes.setOccupancy,
        payload: { ...occupancy }
    })),
    setCabinClass: createAction((cabinClass) => ({
        type: ActionTypes.setCabinClass,
        payload: { cabinClass }
    })),
    setIsDirect: createAction((isDirect) => ({
        type: ActionTypes.setIsDirect,
        payload: { isDirect }
    })),
    swapFromTo: createAction((searchSegmentIndex) => ({
        type: ActionTypes.swapFromTo,
        payload: { searchSegmentIndex }
    })),
    setInitial: createAction((initialValues) => ({
        type: ActionTypes.setInitial,
        payload: { initialValues }
    })),
    resetSegment: createAction((searchSegmentIndex) => ({
        type: ActionTypes.resetSegment,
        payload: { searchSegmentIndex }
    })),
    fetchFareCalendar: createAction(() => ({
        type: ActionTypes.fetchFareCalendar,
        payload: {}
    })),
    fetchFareCalendarSuccess: createAction((fares, from, to) => ({
        type: ActionTypes.fetchFareCalendarSuccess,
        payload: { fares, from, to }
    })),
    fetchFareCalendarError: createAction((error) => ({
        type: ActionTypes.fetchFareCalendarError,
        payload: { error }
    }))
};
