import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { SearchBoxContext } from "../../components/SearchBoxContext/index.js";
import { calcNumberOfNights, formatDate, parseLocation, preventDateFromBeingBefore, useMinMaxDates } from "./utils.js";
import fareCalendarCache from "./fareCalendarCache.js";
import { add, addDays, eachDayOfInterval, endOfDay, endOfMonth, isAfter, isBefore, isWithinInterval, setDate, startOfDay } from "date-fns";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
const splitToken = ".";
const lookupPeriod = 95;
const cache = fareCalendarCache();
const fetchFareCalendar = async (url, { from, to, startDate, outboundDate }, signal) => {
    const placeholder = "http://x";
    const _url = new URL(url, placeholder);
    _url.searchParams.append("from", from);
    _url.searchParams.append("to", to);
    _url.searchParams.append("startDate", startDate);
    _url.searchParams.append("lookupPeriod", String(lookupPeriod));
    if (outboundDate) {
        _url.searchParams.append("outboundDate", outboundDate);
    }
    const key = [from, to, startDate, outboundDate, lookupPeriod].join("");
    const cachedItem = cache.get(key);
    if (cachedItem) {
        return Promise.resolve(cachedItem);
    }
    const data = await fetch(_url.toString().replace(placeholder, ""), {
        credentials: "include",
        headers: { Accept: "application/json" },
        signal
    })
        .then((res) => res.json())
        .catch(() => undefined);
    if (data) {
        const datesList = eachDayOfInterval({
            start: startOfDay(new Date(startDate)),
            end: endOfDay(add(new Date(startDate), { days: lookupPeriod }))
        });
        const calendarFaresMap = data.calendarFares.reduce((acc, n) => {
            if (n.date) {
                acc[n.date] = n;
            }
            return acc;
        }, {});
        const updatedCalendarFares = datesList.map((date) => {
            const dateString = formatDate(date);
            if (calendarFaresMap[dateString]) {
                return calendarFaresMap[dateString];
            }
            return {
                date: dateString,
                fare: { currencyCode: "", units: 0, nanos: 0 },
                error: true
            };
        });
        data.calendarFares = updatedCalendarFares;
        cache.add(key, data);
    }
    return data;
};
const useFareCalendar = (index) => {
    const i18n = useI18n();
    const abortController = useRef([]);
    const { searchType, segments, occupancy, formatPrice, fareCalendarURL, isFareCalendarEnabled, isFareCalendarUIEnabled, isMobile, onGetSBExperimentVariant } = useContext(SearchBoxContext);
    const { minDate, maxDate } = useMinMaxDates(index);
    const { formats } = useFormatDateTime(i18n);
    const [outboundFetchedRangesMap, setOutboundFetchedRangesMap] = useState({});
    const [inboundFetchedRangesMap, setInboundFetchedRangesMap] = useState({});
    const [loading, setLoading] = useState({ inbound: [], outbound: [] });
    const [outboundData, setOutboundData] = useState(undefined);
    const [inboundData, setInboundData] = useState(undefined);
    const [currencyCode, setCurrencyCode] = useState(undefined);
    const [a11yStatus, setA11yStatus] = useState("initial");
    const [a11yLastFetchedOutboundData, setA11yLastFetchedOutboundData] = useState(undefined);
    const [a11yLastFetchedInboundData, setA11yLastFetchedInboundData] = useState(undefined);
    const segment = segments[index];
    const from = segment.from.length > 1 ? undefined : parseLocation(segment.from);
    const to = segment.to.length > 1 ? undefined : parseLocation(segment.to);
    // We can't always rely on the store to gather departure and return dates.
    // Mobile's version of the calendar only commits to the store after confirming changes,
    // hence we need an internal state to keep track of dates change.
    const [{ departureDate, returnDate }, setSelectedDates] = useState({
        departureDate: segment.departureDate,
        returnDate: searchType === "ROUNDTRIP" ? segment.returnDate : undefined
    });
    /* This is purely to help tracking, remove if fullon */
    const etTrackingHelper = useMemo(() => {
        return {
            hasBaseCondition: isFareCalendarEnabled,
            etName: isMobile ? "flights_web_fare_calendar_mdot_v2" : "flights_web_fare_calendar_desktop_v2"
        };
    }, [isFareCalendarEnabled, isMobile]);
    /* ------------------------------------------------- */
    const outboundFetchedRanges = useMemo(() => {
        return Object.keys(outboundFetchedRangesMap).reduce((acc, key) => {
            const [from, to] = key.split(splitToken);
            if (from && to) {
                acc.push([startOfDay(new Date(from)), endOfDay(new Date(to))]);
            }
            return acc;
        }, []);
    }, [outboundFetchedRangesMap]);
    const inboundFetchedRanges = useMemo(() => {
        return Object.keys(inboundFetchedRangesMap).reduce((acc, key) => {
            const [from, to] = key.split(splitToken);
            if (from && to) {
                acc.push([startOfDay(new Date(from)), endOfDay(new Date(to))]);
            }
            return acc;
        }, []);
    }, [inboundFetchedRangesMap]);
    const setA11yLoadingState = useCallback(() => {
        setA11yStatus("loading");
        setA11yLastFetchedOutboundData(undefined);
        setA11yLastFetchedInboundData(undefined);
    }, []);
    const updateStateFromResponse = useCallback((data) => {
        if (!data || !data.calendarFares.length || !data.endDate) {
            return;
        }
        if (!currencyCode) {
            setCurrencyCode(data.calendarFares?.[0]?.fare?.currencyCode || undefined);
        }
        const validDates = data.calendarFares.filter((d) => {
            if (d.date) {
                const isAfterMinDate = isAfter(new Date(d.date), startOfDay(minDate));
                const isBeforeMaxDate = isBefore(new Date(d.date), endOfDay(maxDate));
                if (isAfterMinDate && isBeforeMaxDate) {
                    return true;
                }
            }
            return false;
        });
        if (data.outboundDate) {
            setA11yLastFetchedInboundData(validDates);
        }
        else {
            setA11yLastFetchedOutboundData(validDates);
        }
        const fn = data.outboundDate ? setInboundData : setOutboundData;
        fn((prev) => {
            const updatedDates = { ...(prev || {}) };
            validDates.forEach((d) => {
                if (d.date) {
                    updatedDates[d.date] = d;
                }
            });
            // We are sorting dates here to be easier to display than later.
            const dates = Object.values(updatedDates)
                .sort((a, b) => {
                if (!a.date || !b.date)
                    return 0;
                const aTime = new Date(a.date).getTime();
                const bTime = new Date(b.date).getTime();
                return aTime - bTime;
            })
                .reduce((acc, d) => {
                if (d.date) {
                    acc[d.date] = d;
                }
                return acc;
            }, {});
            return dates;
        });
    }, [currencyCode, maxDate, minDate]);
    const getOutboundData = useCallback(({ from, to, startDate }) => {
        if (!from || !to || !startDate)
            return;
        if (!outboundData) {
            setOutboundData({});
        }
        // Prevent "startDate" data earlier than today
        const _startDate = new Date(startDate);
        const start = preventDateFromBeingBefore(_startDate, new Date());
        const end = addDays(start, lookupPeriod);
        const key = `${start}${splitToken}${end}`;
        setOutboundFetchedRangesMap((prev) => ({ ...prev, [key]: 1 }));
        setLoading((prev) => ({ ...prev, outbound: [...prev.outbound, 1] }));
        /* This "return" keyword is purely to help tracking, remove if fullon */
        return fetchFareCalendar(fareCalendarURL, { from, to, startDate: formatDate(start) })
            .then((data) => {
            updateStateFromResponse(data);
            /* This is purely to help tracking, remove if fullon */
            if (data?.calendarFares.length) {
                return Promise.resolve();
            }
            return Promise.reject();
            /* ------------------------------------------------- */
        })
            .catch((data) => {
            updateStateFromResponse(data);
            setOutboundFetchedRangesMap((prev) => {
                const newState = { ...prev };
                delete newState[key];
                return newState;
            });
            /* This is purely to help tracking, remove if fullon */
            return Promise.reject();
            /* ------------------------------------------------- */
        })
            .finally(() => {
            setA11yStatus((prev) => (prev === "loading" ? "loaded" : prev));
            setLoading((prev) => ({ ...prev, outbound: prev.outbound.slice(1) }));
        });
    }, [fareCalendarURL, outboundData, updateStateFromResponse]);
    const getInboundData = useCallback(({ from, to, startDate, outboundDate }) => {
        if (!from || !to || !startDate || !outboundDate)
            return;
        if (!inboundData) {
            setInboundData({});
        }
        const _startDate = new Date(startDate);
        const _outboundDate = new Date(outboundDate);
        // Prevent "startDate" from being earlier than "outboundDate"
        const start = preventDateFromBeingBefore(_startDate, _outboundDate);
        const end = addDays(start, lookupPeriod);
        const key = `${start}${splitToken}${end}`;
        setInboundFetchedRangesMap((prev) => ({ ...prev, [key]: 1 }));
        setLoading((prev) => ({ ...prev, inbound: [...prev.inbound, 1] }));
        const controller = new AbortController();
        abortController.current.push(controller);
        fetchFareCalendar(fareCalendarURL, { from, to, startDate: formatDate(start), outboundDate }, controller.signal)
            .then(updateStateFromResponse)
            .catch((data) => {
            updateStateFromResponse(data);
            setInboundFetchedRangesMap((prev) => {
                const newState = { ...prev };
                delete newState[key];
                return newState;
            });
        })
            .finally(() => {
            setA11yStatus((prev) => (prev === "loading" ? "loaded" : prev));
            setLoading((prev) => ({ ...prev, inbound: prev.inbound.slice(1) }));
        });
    }, [fareCalendarURL, inboundData, updateStateFromResponse]);
    const clearInboundData = useCallback(() => {
        setInboundData(undefined);
        abortController.current.forEach((c) => c.abort());
    }, []);
    const onOpenCalendar = useCallback(({ departureDate, returnDate }) => {
        const _departureDate = departureDate ? formatDate(departureDate) : undefined;
        const _returnDate = returnDate ? formatDate(returnDate) : undefined;
        // We need to set the dates when opening calendar so we are sure it's synced
        // with the calendar's date internal state.
        setSelectedDates({ departureDate: _departureDate, returnDate: _returnDate });
        let startDate = "";
        // When roundtrip is selected, there is a departure date but NO return date
        // selected, the calendar will open on the current month.
        if (searchType === "ROUNDTRIP" && _departureDate && !_returnDate) {
            startDate = formatDate(new Date());
        }
        else {
            startDate = formatDate(departureDate ? setDate(departureDate, 1) : new Date());
        }
        /* This "void" keyword is purely to help tracking, remove if fullon */
        void getOutboundData({ from, to, startDate });
        /* ------------------------------------------------- */
        if (searchType === "ROUNDTRIP") {
            getInboundData({ from, to, startDate: _departureDate, outboundDate: _departureDate });
        }
    }, [from, getInboundData, getOutboundData, searchType, to]);
    const onCloseCalendar = useCallback(() => {
        setInboundData(undefined);
        setInboundFetchedRangesMap({});
        setOutboundData(undefined);
        setOutboundFetchedRangesMap({});
    }, []);
    const hasDateNotBeenFetched = useCallback((dates, fetchedList) => {
        return !dates.every((date) => fetchedList.some(([start, end]) => isWithinInterval(date, { start, end })));
    }, []);
    /* Used for Desktop and Mdot when navigating through months */
    const onCalendarNavigate = useCallback((calendarDate, mode) => {
        const firstDay = startOfDay(calendarDate);
        // For Desktop, we check the first day of the month on the left and last day of month on the right.
        // For Mdot, we check the first and last day of the visible month.
        const lastDay = mode === "double" ? endOfDay(endOfMonth(add(calendarDate, { months: 1 }))) : endOfDay(endOfMonth(firstDay));
        const isVariant = !!onGetSBExperimentVariant(etTrackingHelper.etName);
        let shouldFetchOutbound = hasDateNotBeenFetched([firstDay, lastDay], outboundFetchedRanges);
        shouldFetchOutbound =
            isVariant && searchType === "ROUNDTRIP" ? shouldFetchOutbound && !departureDate : shouldFetchOutbound;
        let shouldFetchInbound = searchType === "ROUNDTRIP" && !returnDate && hasDateNotBeenFetched([firstDay, lastDay], inboundFetchedRanges);
        shouldFetchInbound = isVariant ? shouldFetchInbound && !returnDate : shouldFetchInbound;
        if (shouldFetchOutbound) {
            setA11yLoadingState();
            /* This "void" keyword is purely to help tracking, remove if fullon */
            void getOutboundData({ from, to, startDate: formatDate(calendarDate) });
            /* ------------------------------------------------- */
        }
        if (shouldFetchInbound) {
            setA11yLoadingState();
            getInboundData({ from, to, startDate: formatDate(calendarDate), outboundDate: departureDate });
        }
    }, [
        departureDate,
        etTrackingHelper.etName,
        from,
        getInboundData,
        getOutboundData,
        hasDateNotBeenFetched,
        inboundFetchedRanges,
        onGetSBExperimentVariant,
        outboundFetchedRanges,
        returnDate,
        searchType,
        setA11yLoadingState,
        to
    ]);
    /* Used for Desktop and Mdot when navigating through months with the calendar's keyboard version */
    const onCalendarKeyboardNavigate = useCallback(({ departureYearMonth, returnYearMonth }) => {
        const date = departureYearMonth || returnYearMonth;
        if (!date)
            return;
        // When changing year-month the day is reset, we need to update our internal state.
        setSelectedDates((prev) => {
            if (departureYearMonth) {
                return { departureDate: undefined, returnDate: undefined };
            }
            return { ...prev, returnDate: undefined };
        });
        const firstDay = startOfDay(date);
        const lastDay = endOfDay(endOfMonth(firstDay));
        const shouldFetchOutbound = hasDateNotBeenFetched([firstDay, lastDay], outboundFetchedRanges);
        const shouldFetchInbound = searchType === "ROUNDTRIP" &&
            departureDate &&
            returnYearMonth &&
            hasDateNotBeenFetched([firstDay, lastDay], inboundFetchedRanges);
        if (shouldFetchOutbound) {
            setA11yLoadingState();
            /* This "void" keyword is purely to help tracking, remove if fullon */
            void getOutboundData({ from, to, startDate: formatDate(date) });
            /* ------------------------------------------------- */
        }
        if (shouldFetchInbound) {
            setA11yLoadingState();
            clearInboundData();
            getInboundData({ from, to, startDate: formatDate(date), outboundDate: departureDate });
        }
    }, [
        clearInboundData,
        departureDate,
        from,
        getInboundData,
        getOutboundData,
        hasDateNotBeenFetched,
        inboundFetchedRanges,
        outboundFetchedRanges,
        searchType,
        setA11yLoadingState,
        to
    ]);
    const onChangeSelectedDates = useCallback(({ departureDate, returnDate }) => {
        const _departureDate = departureDate ? formatDate(departureDate) : undefined;
        const _returnDate = returnDate ? formatDate(returnDate) : undefined;
        setSelectedDates({ departureDate: _departureDate, returnDate: _returnDate });
        if (searchType === "ROUNDTRIP" && _departureDate && !_returnDate) {
            setA11yLoadingState();
            clearInboundData();
            getInboundData({ from, to, startDate: _departureDate, outboundDate: _departureDate });
        }
    }, [clearInboundData, from, getInboundData, searchType, setA11yLoadingState, to]);
    const isLoading = useMemo(() => !!loading.inbound.length || !!loading.outbound.length, [loading.inbound.length, loading.outbound.length]);
    const data = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        // Variants behaviour is documented at https://www.figma.com/design/k9rVDpu89jCvBcIbuaCqYC/Fare-Calendar?node-id=5324-39344&t=6KsLtLArN0V1Ra8A-0
        // -- Variant 2
        if (!!onGetSBExperimentVariant(etTrackingHelper.etName)) {
            if (searchType === "ONEWAY") {
                return outboundData;
            }
            // If it's a round trip, no return date is selected and it's loading
            // we hide every price.
            if (isLoading) {
                return {};
            }
            // If a return date is selected, we only show price for it.
            if (inboundData && returnDate && inboundData[returnDate]) {
                return { [returnDate]: inboundData[returnDate] };
            }
            if (inboundData && departureDate) {
                return inboundData;
            }
            return undefined;
        }
        // -- Variant 1
        if (searchType === "ONEWAY" || returnDate) {
            return outboundData;
        }
        // If it's a round trip, no return date is selected and it's loading
        // we should only show prices before selected outbound date.
        if (isLoading) {
            return outboundData
                ? Object.values(outboundData).reduce((acc, o) => {
                    if (!o.date || !departureDate)
                        return acc;
                    if (isBefore(new Date(o.date), new Date(departureDate))) {
                        acc[o.date] = o;
                    }
                    return acc;
                }, {})
                : undefined;
        }
        // We show "outboundDates" until the selected departure date
        let result = undefined;
        if (outboundData) {
            result = { ...outboundData };
        }
        if (result && inboundData && departureDate) {
            // Prevent showing any "outboundData" that is equal or after "departureDate".
            const filteredResult = {};
            for (const dateString of Object.keys(result)) {
                if (!isBefore(new Date(dateString), new Date(departureDate))) {
                    break;
                }
                filteredResult[dateString] = result[dateString];
            }
            result = { ...filteredResult, ...inboundData };
        }
        return result;
    }, [
        departureDate,
        etTrackingHelper.etName,
        inboundData,
        isFareCalendarUIEnabled,
        isLoading,
        onGetSBExperimentVariant,
        outboundData,
        returnDate,
        searchType
    ]);
    const boundsData = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        return {
            outbound: outboundData,
            inbound: inboundData
        };
    }, [inboundData, isFareCalendarUIEnabled, outboundData]);
    const selectedPrice = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        if (data && searchType === "ROUNDTRIP" && returnDate) {
            const fare = inboundData?.[returnDate]?.fare;
            return fare ? formatPrice(fare) : undefined;
        }
        if (data && searchType === "ONEWAY" && departureDate) {
            const fare = outboundData?.[departureDate]?.fare;
            return fare ? formatPrice(fare) : undefined;
        }
        return undefined;
    }, [data, departureDate, formatPrice, inboundData, isFareCalendarUIEnabled, outboundData, returnDate, searchType]);
    const selectedPriceText = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        if (selectedPrice) {
            if (occupancy.adults + occupancy.children.length > 1) {
                return i18n.trans(t("flights_mdot_fare_calendar_price_multi"));
            }
            return i18n.trans(t("flights_mdot_fare_calendar_price_solo"));
        }
        return undefined;
    }, [i18n, isFareCalendarUIEnabled, occupancy.adults, occupancy.children.length, selectedPrice]);
    const currencyText = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        if (data && currencyCode) {
            if (occupancy.adults + occupancy.children.length > 1) {
                return i18n.trans(t("flights_fare_calendar_showing_price_multi", { variables: { short_currency: currencyCode } }));
            }
            return i18n.trans(t("flights_fare_calendar_showing_price_solo", { variables: { short_currency: currencyCode } }));
        }
        return undefined;
    }, [currencyCode, data, i18n, isFareCalendarUIEnabled, occupancy.adults, occupancy.children.length]);
    const selectedDatesText = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        if (data && searchType === "ROUNDTRIP") {
            if (departureDate && returnDate && !selectedPrice) {
                const numberOfNights = calcNumberOfNights(new Date(departureDate), new Date(returnDate));
                return i18n.trans(t("flights_fare_calendar_rt_trip_length", {
                    variables: {
                        departure_date: formats.flightDateWeekday(departureDate),
                        return_date: formats.flightDateWeekday(returnDate),
                        num_nights: numberOfNights,
                        num_exception: numberOfNights
                    }
                }));
            }
            if (departureDate) {
                return i18n.trans(t("flights_fare_calendar_rt_select_return", {
                    variables: { departure_date: formats.flightDateWeekday(departureDate) }
                }));
            }
            return i18n.trans(t("flights_fare_calendar_select_departure"));
        }
        if (data && searchType === "ONEWAY" && departureDate) {
            return formats.flightDateWeekday(departureDate);
        }
        return undefined;
    }, [data, departureDate, formats, i18n, isFareCalendarUIEnabled, returnDate, searchType, selectedPrice]);
    const a11yAnnounceData = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        if (!data)
            return;
        let lowestPricesData = undefined;
        const a11yLowerPricesData = a11yLastFetchedInboundData || a11yLastFetchedOutboundData;
        if (a11yStatus === "loaded" && a11yLowerPricesData) {
            lowestPricesData = a11yLowerPricesData.reduce((acc, d) => {
                if (d.date) {
                    // We display rounded prices, so we round up here for easy comparison.
                    const farePriceRounded = d.fare.units + (d.fare.nanos > 0 ? 1 : 0);
                    const accPriceRounded = acc.price.units + (acc.price.units > 0 ? 1 : 0);
                    if (farePriceRounded < accPriceRounded) {
                        acc.price = d.fare;
                        acc.dates = [d.date];
                    }
                    if (farePriceRounded === accPriceRounded) {
                        acc.dates.push(d.date);
                    }
                }
                return acc;
            }, { dates: [], price: { currencyCode: "", units: Infinity, nanos: 0 } });
        }
        return { status: a11yStatus, lowestPricesData };
    }, [a11yLastFetchedInboundData, a11yLastFetchedOutboundData, a11yStatus, data, isFareCalendarUIEnabled]);
    /* This is purely to help tracking, remove if fullon */
    const getSelectedFareRange = useCallback((from, to) => {
        const result = { oneWay: undefined, roundTrip: undefined };
        const fromString = from && formatDate(from);
        const toString = to && formatDate(to);
        const outboundFare = fromString ? outboundData?.[fromString] : undefined;
        const inboundFare = toString ? inboundData?.[toString] : undefined;
        result.roundTrip = inboundFare
            ? inboundFare?.fareRange === "LOW"
                ? inboundFare?.fareRange
                : "REGULAR"
            : undefined;
        result.oneWay = outboundFare
            ? outboundFare?.fareRange === "LOW"
                ? outboundFare?.fareRange
                : "REGULAR"
            : undefined;
        return result;
    }, [inboundData, outboundData]);
    /* ------------------------------------------------- */
    return {
        data,
        boundsData,
        isLoading,
        selectedPrice,
        selectedPriceText,
        currencyText,
        selectedDatesText,
        a11yAnnounceData,
        etTrackingHelper,
        getSelectedFareRange,
        onChangeSelectedDates,
        onOpenCalendar,
        onCloseCalendar,
        onCalendarNavigate,
        onCalendarKeyboardNavigate
    };
};
export default useFareCalendar;
