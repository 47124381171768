import React, { useCallback, useEffect, useMemo } from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useSearchFiltersController from "../useSearchFiltersController";
import { InputCheckbox, Stack, Text } from "@bookingcom/bui-react";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import useProfiler from "hooks/useProfiler";
import { useFormatDateTime } from "@bookingcom/flights-core/hooks";
import { flightsSessionStore } from "utils/session-store";

export default function PopularFilters() {
  const { popularAirlines, cabinBaggage, earlyDeparture, morningDeparture, lateArrival, shortLayoverConnection } =
    useSearchFiltersController();

  const noOfFilters = useMemo(
    () =>
      [popularAirlines, cabinBaggage, earlyDeparture, morningDeparture, lateArrival, shortLayoverConnection].filter(
        (_) => !_.disabled
      ).length,
    [popularAirlines, cabinBaggage, earlyDeparture, morningDeparture, lateArrival, shortLayoverConnection]
  );
  const isAnyFilter = useMemo(() => noOfFilters > 0, [noOfFilters]);
  const i18n = useI18n();
  const profiler = useProfiler();
  const { formats } = useFormatDateTime(i18n);

  const variant = useMemo(() => (!!trackExperiment("flights_web_sr_filter_top_filters_checkbox") ? 1 : 0), []);

  const isFirstInteractionTracked = useCallback(() => {
    return !!flightsSessionStore?.get?.("flights_web_sr_filter_top_filters_checkbox");
  }, []);

  const flagFirstInteractionTracked = useCallback(() => {
    return flightsSessionStore?.set?.("flights_web_sr_filter_top_filters_checkbox", "1", { ttl: "60m" });
  }, []);

  const getFormattedTime = useCallback(
    (start?: string | null, end?: string | null) => {
      if (!start || !end) return "";
      const startTime = `2000-01-01T${start}:00`;
      const endTime = `2000-01-01T${end}:00`;
      return !!trackExperiment("flights_web_q3_blackout")
        ? `${start} - ${end}`
        : i18n.trans(
            t("flights_filters_times_range", {
              variables: {
                start_time: formats.flightTime(startTime),
                end_time: formats.flightTime(endTime)
              }
            })
          );
    },
    [i18n, formats]
  );

  useEffect(() => {
    if (!isAnyFilter) return;
    trackExperimentStage("flights_web_sr_filter_top_filters_checkbox", 1);
    trackExperimentStage("flights_web_sr_filter_top_filters_checkbox", profiler.isDesktop() ? 2 : 3);
    trackExperimentStage("flights_web_sr_filter_top_filters_checkbox", profiler.isDirect() ? 4 : 5);
    trackExperimentStage("flights_web_sr_filter_top_filters_checkbox", profiler.isSolo() ? 6 : 7);
    if (!isFirstInteractionTracked() && noOfFilters <= 3)
      trackExperimentStage("flights_web_sr_filter_top_filters_checkbox", 8);
  }, [profiler, isAnyFilter, noOfFilters, isFirstInteractionTracked]);

  const trackGoal = useCallback(
    (goal: number) => {
      trackCustomGoal("flights_web_sr_filter_top_filters_checkbox", 5);
      if (!isFirstInteractionTracked()) {
        trackCustomGoal("flights_web_sr_filter_top_filters_checkbox", goal);
        flagFirstInteractionTracked();
      }
    },
    [isFirstInteractionTracked, flagFirstInteractionTracked]
  );

  if (!variant) return null;

  return (
    <Stack>
      <Text variant="emphasized_1">{i18n.trans(t("flights_sr_filters_popular_filters_title"))}</Text>
      {/* popularAirlines ================================================== */}
      {popularAirlines ? (
        <InputCheckbox
          name="popularAirlines"
          checked={popularAirlines.checked}
          disabled={popularAirlines.disabled}
          onChange={({ checked }) => {
            trackGoal(1);
            return checked ? popularAirlines.set() : popularAirlines.reset();
          }}
          label={
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="body_2">{i18n.trans(t("flights_sr_filters_most_popular_airlines"))}</Text>
                {popularAirlines.names?.length ? (
                  <Text color="neutral_alt">
                    {i18n.trans(
                      t("flights_sr_filters_airline_names_multi", {
                        variables: {
                          airline_name1: popularAirlines.names?.[0] || "",
                          airline_name2: popularAirlines.names?.[1] || "",
                          num_others: popularAirlines.names?.length || 0,
                          num_exception: popularAirlines.names?.length || 0
                        }
                      })
                    )}
                  </Text>
                ) : null}
              </Stack.Item>
              <Stack.Item>{popularAirlines.count}</Stack.Item>
            </Stack>
          }
        />
      ) : null}

      {/* cabinBaggage ===================================================== */}
      {cabinBaggage ? (
        <InputCheckbox
          name="cabinBaggage"
          checked={cabinBaggage.checked}
          disabled={cabinBaggage.disabled}
          onChange={({ checked }) => {
            trackGoal(2);
            return checked ? cabinBaggage.set() : cabinBaggage.reset();
          }}
          label={
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="body_2">{i18n.trans(t("flights_sr_filters_cabin_bags_included"))}</Text>
                <Text color="neutral_alt">{i18n.trans(t("flights_sr_filters_no_additional_fee"))}</Text>
              </Stack.Item>
              <Stack.Item>{cabinBaggage.count}</Stack.Item>
            </Stack>
          }
        />
      ) : null}

      {/* earlyDeparture =================================================== */}
      {earlyDeparture ? (
        <InputCheckbox
          name="earlyDeparture"
          checked={earlyDeparture.checked}
          disabled={earlyDeparture.disabled}
          onChange={({ checked }) => {
            trackGoal(3);
            return checked ? earlyDeparture.set() : earlyDeparture.reset();
          }}
          label={
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="body_2">{i18n.trans(t("flights_sr_filters_outbound_early"))}</Text>
                <Text color="neutral_alt">({getFormattedTime("00:00", "05:59")})</Text>
              </Stack.Item>
              <Stack.Item>{earlyDeparture.count}</Stack.Item>
            </Stack>
          }
        />
      ) : null}

      {/* morningDeparture ================================================= */}
      {morningDeparture ? (
        <InputCheckbox
          name="morningDeparture"
          checked={morningDeparture.checked}
          disabled={morningDeparture.disabled}
          onChange={({ checked }) => {
            trackGoal(3);
            return checked ? morningDeparture.set() : morningDeparture.reset();
          }}
          label={
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="body_2">{i18n.trans(t("flights_sr_filters_outbound_morning"))}</Text>
                <Text color="neutral_alt">({getFormattedTime("06:00", "11:59")})</Text>
              </Stack.Item>
              <Stack.Item>{morningDeparture.count}</Stack.Item>
            </Stack>
          }
        />
      ) : null}

      {/* lateArrival ====================================================== */}
      {lateArrival ? (
        <InputCheckbox
          name="lateArrival"
          checked={lateArrival.checked}
          disabled={lateArrival.disabled}
          onChange={({ checked }) => {
            trackGoal(3);
            return checked ? lateArrival.set() : lateArrival.reset();
          }}
          label={
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="body_2">{i18n.trans(t("flights_sr_filters_return_late"))}</Text>
                <Text color="neutral_alt">({getFormattedTime("18:00", "23:59")})</Text>
              </Stack.Item>
              <Stack.Item>{lateArrival.count}</Stack.Item>
            </Stack>
          }
        />
      ) : null}

      {/* shortLayoverConnection =========================================== */}
      {shortLayoverConnection ? (
        <InputCheckbox
          name="shortLayoverConnection"
          checked={shortLayoverConnection.checked}
          disabled={shortLayoverConnection.disabled}
          onChange={({ checked }) => {
            trackGoal(4);
            return checked ? shortLayoverConnection.set() : shortLayoverConnection.reset();
          }}
          label={
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="body_2">{i18n.trans(t("flights_sr_filters_short_layover"))}</Text>
                <Text color="neutral_alt">{i18n.trans(t("flights_sr_filters_short_layover_duration"))}</Text>
              </Stack.Item>
              <Stack.Item>{shortLayoverConnection.count}</Stack.Item>
            </Stack>
          }
        />
      ) : null}
    </Stack>
  );
}
