import { useCallback } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../store";
import useEventTracking from "hooks/useEventTracking";
import useGlobalContext from "hooks/useGlobalContext";
import useTrackerContext from "hooks/useTrackerContext";
import useClientMetrics from "hooks/useClientMetrics";
import { trackCustomGoal, trackGoal } from "utils/et";
import { transformToc360CData } from "../utils";
import { CarouselProps, UIXComponentType } from "../types";
import {
  TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
  TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_GOALS
} from "constants/experiments";

export const useClickHandlers = (componentType: UIXComponentType, props?: CarouselProps) => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const { order } = store.order;
  const { crossSell: carouselData } = store.crossSell;
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("xsell", requestId);
  const { trackExternal } = useTrackerContext();
  const { trackClientMetric } = useClientMetrics();

  const handleCardClick = useCallback(
    (id: number, index: number, recResultsId: string, targetURL: string) => {
      trackGoal("flights_web_xsell_carousel_card_click");
      trackGoal("flights_web_xsell_carousel_click");
      trackCustomGoal(
        TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
        TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_GOALS.CLICKED_CROSS_SELL_CARD
      );

      trackExternal({
        type: "c360",
        args: [transformToc360CData({ type: "clicked", hotelId: id, index })]
      });

      trackV2("xsell_recommendation_click", {
        orderid: order?.orderId || "",
        rec_results_id: recResultsId,
        position: index,
        url: targetURL,
        request_id: carouselData?.meta.requestId || "",
        componentType: componentType,
        soylentId: order?.soylentEmail || ""
      });
      trackClientMetric("xsell_recommendation_click");

      if (props?.onItemClick) {
        props.onItemClick();
      }
    },
    [
      componentType,
      trackExternal,
      trackV2,
      order?.orderId,
      order?.soylentEmail,
      carouselData?.meta.requestId,
      trackClientMetric,
      props
    ]
  );

  const handleButtonClick = useCallback(
    (targetURL: string, button: string) => {
      trackGoal("flights_web_xsell_carousel_cta_click");
      trackGoal("flights_web_xsell_carousel_click");
      trackCustomGoal(
        TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF,
        TRIPS_INITIATIVES_DDOT_MDOT_FLIGHT_TO_ABU_XSELL_PB_TF_GOALS.CLICKED_CROSS_SELL_CTA
      );

      trackV2("xsell_button_click", {
        orderid: order?.orderId || "",
        button: button,
        url: targetURL,
        request_id: carouselData?.meta.requestId || "",
        componentType: componentType,
        soylentId: order?.soylentEmail || ""
      });
      trackClientMetric("xsell_button_click");

      if (props?.onAction) {
        props.onAction();
      }

      const blankWindow = window.open(targetURL, "_blank");
      if (blankWindow) {
        blankWindow.focus();
      }
    },
    [
      componentType,
      trackV2,
      order?.orderId,
      order?.soylentEmail,
      carouselData?.meta.requestId,
      trackClientMetric,
      props
    ]
  );

  const handleNextButton = useCallback(() => {
    trackGoal("flights_web_xsell_carousel_next_click");

    if (props?.onNext) {
      props.onNext();
    }
  }, [props]);

  const handlePreviousButton = useCallback(() => {
    trackGoal("flights_web_xsell_carousel_previous_click");

    if (props?.onPrevious) {
      props.onPrevious();
    }
  }, [props]);

  return {
    handleButtonClick,
    handleCardClick,
    handleNextButton,
    handlePreviousButton
  };
};
