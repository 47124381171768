import React, { useCallback, useState } from "react";
import { InputCheckbox } from "@bookingcom/bui-react";
import useDirectFlight from "./useDirectFlight.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./DirectFlightCheckbox.module.css";
import { isKeyboardFocusEvent } from "../../utils/isKeyboardFocusEvent.js";
const DirectFlightCheckbox = () => {
    const [isFocused, setIsFocused] = useState(false);
    const { i18n, toggleIsDirect, isDirect, trackV2, isNewUiExp, showNonStopExp } = useDirectFlight();
    const handleOnChange = useCallback((checked) => {
        if (checked)
            trackV2("select_direct_flights_only");
        toggleIsDirect(checked);
    }, [toggleIsDirect, trackV2]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(InputCheckbox, { name: "sr_direct_flights", label: showNonStopExp ? i18n.trans(t("flights_search_nonstop_us")) : i18n.trans(t("flights_search_direct")), onChange: ({ checked }) => {
                handleOnChange(checked);
            }, checked: isDirect, id: "direct_flights_input_checkbox", className: `${styles.input} ${isNewUiExp ? styles.inputDark : ""} ${isNewUiExp && isDirect ? styles.newInputChecked : ""} ${isNewUiExp && isFocused ? styles.focusedCheckbox : ""}`, attributes: { "data-ui-name": "direct_flights" }, inputAttributes: { "data-ui-name": "direct_flights_input" }, onFocus: (event) => {
                isKeyboardFocusEvent(event) && setIsFocused(true);
            }, onBlur: () => setIsFocused(false) })));
};
export default DirectFlightCheckbox;
